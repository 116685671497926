import React from 'react';
import { Helmet } from 'react-helmet';
import { getCookie } from '../utils/helpers';
import ReactPixel from 'react-facebook-pixel';

const setFacebookCookies = () => {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, {}, options);
  ReactPixel.pageView();
};

const Seo = ({
  title = 'Festival della Scienza online',
  description = 'Festival della Scienza online',
  robots
}) => (
  <Helmet
    title={title}
    meta={[{ name: 'description', content: description }].concat(
      robots ? [{ name: 'robots', content: robots }] : []
    )}
  >
    {process.env.REACT_APP_FB_PIXEL_ID && getCookie('CookieConsent') && setFacebookCookies()}
  </Helmet>
);

export default Seo;
