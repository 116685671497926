import React from 'react';
import Breadcrumbs from '../components/breadcrumbs';
import Seo from '../components/seo';
import Faq from '../components/faq';
import faqs from '../data/faqs.json';
import { Redirect } from '@reach/router';
import { useAuth } from '../context/auth';

const breadcrumbs = [{ label: 'FAQ' }];

const Informazioni = () => {
  const { user } = useAuth();
  if (!user) {
    return <Redirect to="/login" noThrow />;
  }

  return (
    <div className="container mb-5">
      <Seo title="Informazioni - Festival della Scienza online" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <h1>FAQ</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <h2 className="mt-3 mb-4">Domande frequenti</h2>
          {faqs.faqs.map((faq, i) => (
            <Faq title={faq.question} className="mb-4" key={i}>
              <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
            </Faq>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Informazioni;
