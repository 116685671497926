const updateEntries = (entries, current) => {
  // Cerco se ho già inserito il valore
  const entry = entries.find((item) => item.value === current.value);
  if (entry) {
    // Se c'è incremento il contatore
    entry.count++;
  } else {
    // Altrimenti lo inserisco con contatore a 1
    entries.push({
      ...current,
      count: 1
    });
  }
};

const computeAllValues = (facet, items) => {
  return items.reduce((entries, item) => {
    const itemValue = facet.extract(item);
    if (!itemValue) return entries;
    const normalizedValues = Array.isArray(itemValue) ? itemValue : [itemValue];
    for (let singleValue of normalizedValues) {
      if (!singleValue.value) continue;
      const entry = entries.some((item) => item.value === singleValue.value);
      if (!entry) {
        entries.push(singleValue);
      }
    }
    return entries;
  }, []);
};

const computeFacetValues = (facets, items) => {
  return facets.map((facet) => ({
    ...facet,
    allValues: computeAllValues(facet, items)
  }));
};

let memoizedItems;
let memoizedFacets;

const getAllFacets = (facets, items) => {
  if (memoizedItems !== items) {
    memoizedFacets = computeFacetValues(facets, items);
    memoizedItems = items;
  }
  return memoizedFacets;
};

const extractValues = (facet, items, filters, query) => {
  // Valore corrente faccetta in query
  const facetValue = query[facet.id];
  const facetNormalized = Array.isArray(facetValue) ? facetValue : [facetValue];
  // Tutte le altre faccette col rispettivo valore in query
  const allOtherFacets = filters
    .filter((elem) => elem.id !== facet.id && !!query[elem.id])
    .map((elem) => ({ filter: elem, value: query[elem.id] }));
  const filterValues = items.reduce((entries, item) => {
    // Match su tutte le altre faccette
    if (
      allOtherFacets.every((facet) =>
        Array.isArray(facet.value)
          ? facet.value.some((value) => facet.filter.match(item, value))
          : facet.filter.match(item, facet.value)
      )
    ) {
      // Estraggo i valori dall'elemento
      const itemValue = facet.extract(item);
      if (!itemValue) return entries;
      if (Array.isArray(itemValue)) {
        const validValues = itemValue.filter((item) => !!item.value);
        for (let singleValue of validValues) {
          updateEntries(entries, singleValue);
        }
      } else {
        updateEntries(entries, itemValue);
      }
    }
    return entries;
  }, []);
  // console.log('facet: ', facet.id, JSON.stringify(filterValues));
  return facet.allValues
    .reduce((res, entry) => {
      const active = facetNormalized.includes(entry.value);
      const validItem = filterValues.find((elem) => elem.value === entry.value);
      // Inserisci le faccette valide e le altre con count 0
      res.push(
        validItem
          ? {
              ...validItem,
              active
            }
          : {
              ...entry,
              active,
              count: 0
            }
      );
      return res;
    }, []) // Prima quelle attive, prime quelle con count !== 0, poi in ordine dipendente dalla faccetta
    .sort((a, b) => {
      if (a.active === b.active) {
        if ((a.count === 0 || b.count === 0) && a.count !== b.count) {
          // Se uno dei 2 ha un count 0 e diverso dall'altro, vince chi ha il count più alto
          return a.count > b.count ? -1 : 1;
        }
        // Ordinamento specifico per faccetta
        return facet.sort ? facet.sort(a, b) : 0;
      }
      // Se uno dei 2 ha il flag active e l'altro no, va per primo
      return a.active ? -1 : 1;
    });
};

const calculateFacets = (facets, items, query) => {
  // Calcola tutte le faccette possibili
  const allFacets = getAllFacets(facets, items);
  return allFacets.map((facet) => ({
    ...facet,
    values: extractValues(facet, items, allFacets, query)
  }));
};

export default calculateFacets;
