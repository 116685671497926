import React, { useCallback, useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { Redirect, Link } from '@reach/router';
import { useAuth } from '../context/auth';
import Breadcrumbs from '../components/breadcrumbs';
import ForgotPasswordModal from '../components/forgot-password-modal';
import { getErrorMessage } from '../utils/cognito-error-messages';
import Seo from '../components/seo';

const breadcrumbs = [{ label: 'Login' }];

const Login = ({ location }) => {
  const { email: precompiledEmail, referer } = location.state || {};
  const { user, userData, saveUser } = useAuth();

  const [forgotModalVisible, setForgotModalVisible] = useState(false);
  const showForgotModal = useCallback((e) => {
    e.preventDefault();
    setForgotModalVisible(true);
  }, []);
  const closeForgotModal = useCallback(() => setForgotModalVisible(false), []);

  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({ email: precompiledEmail || '', password: '' });
  const { email, password } = formData;
  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((data) => ({
      ...data,
      [name]: name === 'email' || name === 'email2' ? value && value.toLowerCase() : value
    }));
  }, []);

  const validParameters = email && password;

  const login = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      if (!validParameters) {
        console.warn('Invalid parameters');
        return;
      }
      setLoading(true);
      try {
        await Auth.signIn(email, password);
      } catch (err) {
        console.error('Error in login: ', err);
        setLoading(false);
        if (err.code === 'UserNotConfirmedException') {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
          console.log('UserNotConfirmedException');
        } else if (err.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
          console.log('UserNotConfirmedException');
          setFormData((data) => ({ ...data, password: '' }));
        } else if (err.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
          console.log('NotAuthorizedException');
        } else if (err.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
          console.log('UserNotFoundException');
        }
        setError(getErrorMessage(err));
        return;
      }
      const loggedUser = await Auth.currentAuthenticatedUser();
      saveUser(loggedUser);
      setFormData({ email: '', password: '', otp: '' });
      setError('');
    },
    [saveUser, email, password, validParameters]
  );

  const forgotPassword = useCallback(
    async (email) => {
      if (!email) {
        console.warn('Email necessaria');
        return;
      }
      setLoadingCode(true);
      try {
        const data = await Auth.forgotPassword(email);
        console.log('forgotPassword: ', data);
        setFormData((data) => ({ ...data, email }));
        setError('');
      } catch (err) {
        console.error('Error in forgotPassword: ', err);
        setError(getErrorMessage(err));
      }
      setLoadingCode(false);
      closeForgotModal();
    },
    [closeForgotModal]
  );

  useEffect(() => {
    if (loading && user && userData) {
      setLoading(false);
    }
  }, [loading, user, userData]);

  if (user && !loading) {
    if (userData && userData.abbonamento && userData.expireDate) {
      return <Redirect to="/accesso/" noThrow />;
    } else {
      return <Redirect to={referer ? referer.pathname : '/'} noThrow />;
    }
  }

  return (
    <div className="container">
      <Seo title="Login - Festival della Scienza online" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>Login</h1>
          <form className="form" onSubmit={login}>
            <div className="mb-3">
              <label className="form__label">
                Email
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  placeholder=""
                  disabled={loading || loadingCode}
                  maxLength={320}
                  className="form__field"
                />
              </label>
            </div>
            <div className="my-3">
              <label className="form__label">
                Password
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  placeholder=""
                  disabled={loading || loadingCode}
                  maxLength={320}
                  className="form__field"
                />
              </label>
            </div>
            {error && !loading ? (
              <div className="my-3 form__err-message">
                <p>{error}</p>
              </div>
            ) : null}
            <div className="text-small">
              <p>
                Occorre registrarsi su questo sito tramite la sezione{' '}
                <Link to="/registrati/">registrati</Link>.
              </p>
            </div>
            <div className="d-flex flex-column align-items-end">
              <button type="button" className="link my-4" onClick={showForgotModal}>
                Hai dimenticato la password?
              </button>
              <button
                type="submit"
                className="btn"
                disabled={loading || loadingCode || !validParameters}
              >
                {loading ? (
                  <div className="d-flex align-items-center">
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Caricamento...
                  </div>
                ) : (
                  'Accedi'
                )}
              </button>
            </div>
          </form>
          <div className="test-small mt-3">
            <p>
              Non ha un account? <Link to="/registrati/">Registrati</Link>
            </p>
          </div>
        </div>
      </div>
      <ForgotPasswordModal
        show={forgotModalVisible}
        onHide={closeForgotModal}
        submit={forgotPassword}
        email={email}
        loading={loadingCode}
      />
    </div>
  );
};

export default Login;
