import React from 'react';
import { Link } from '@reach/router';

class NavLink extends React.Component {
  defaultGetProps = ({ isPartiallyCurrent, isCurrent }) => {
    if (this.props.exactActive ? isCurrent : isPartiallyCurrent) {
      return {
        className: [this.props.className, this.props.activeClassName].filter(Boolean).join(` `),
        style: { ...this.props.style, ...this.props.activeStyle }
      };
    }
    return null;
  };
  render() {
    const { activeClassName, activeStyle, exactActive, ...props } = this.props;
    return <Link getProps={this.defaultGetProps} {...props} />;
  }
}

export default NavLink;
