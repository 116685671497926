import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Index } from 'flexsearch';
import debounce from 'lodash.debounce';
import { navigate } from '@reach/router';
import { facets } from '../utils/facets';
import calculateFacets from '../utils/filtering';
import { parseQueryString, stringifyQuery } from '../utils/query-utils';

const facetKeys = facets.map((facet) => facet.id);

const initReducer = (queryString) => {
  const { text = '', ...query } = parseQueryString(queryString, facetKeys.concat('text'));
  // console.log('initReducer: ', queryString, text);
  return {
    text,
    query,
    searchIndex: null,
    documentsMap: null
  };
};

const searchReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'CHANGE_TEXT':
      return {
        ...state,
        query: {},
        text: payload
      };
    case 'CHANGE_QUERY':
      return {
        ...state,
        query: payload
      };
    case 'LOADED_INDEX':
      return {
        ...state,
        searchIndex: payload.searchIndex,
        documentsMap: payload.documentsMap
      };
    default:
      return state;
  }
};

const useSearchEvents = (events, location) => {
  const [{ text, query, searchIndex, documentsMap }, dispatch] = useReducer(
    searchReducer,
    location.hash.slice(1),
    initReducer
  );

  useEffect(() => {
    if (events && !searchIndex) {
      const populateIndex = async () => {
        const start = Date.now();
        const index = new Index({
          tokenize: 'full'
        });
        const documentsMap = events.reduce((res, row) => {
          const id = parseInt(row.id);
          res.set(id, row);
          return res;
        }, new Map());
        await Promise.all(
          events.map(async (row) => {
            const id = parseInt(row.id);
            await index.addAsync(id, row.titolo);
            await index.appendAsync(id, row.sottotitolo);
            const parole_chiave = row.parole_chiave.split('#');
            await Promise.all(parole_chiave.map((word) => index.appendAsync(id, word)));
            await Promise.all(
              row.relatori.map(async (relatore) => {
                await index.appendAsync(id, relatore.nome);
                await index.appendAsync(id, relatore.cognome);
              })
            );
          })
        );
        console.log('indexing time: ', (Date.now() - start) / 1000);
        return [index, documentsMap];
      };
      populateIndex().then(([index, documentsMap]) => {
        dispatch({ type: 'LOADED_INDEX', payload: { searchIndex: index, documentsMap } });
      });
    }
  }, [searchIndex, events]);

  const [searchEvents, setSearchEvents] = useState(events);

  const searchByText = useCallback(
    debounce(async (text) => {
      if (searchIndex) {
        const results = await searchIndex.searchAsync(text, events.length);
        const resMap = new Map();
        const resFiltered = [];
        for (let item of results) {
          if (!resMap.has(item)) {
            resFiltered.push(documentsMap.get(item));
            resMap.set(item, true);
          }
        }
        console.log(`Found ${resFiltered.length} results`);
        setSearchEvents(resFiltered);
      }
    }, 300),
    [documentsMap, events, searchIndex]
  );

  useEffect(() => {
    if (searchIndex) {
      if (text.length > 1) {
        searchByText(text);
      } else {
        setSearchEvents(events);
      }
    }
  }, [text, events, searchByText, searchIndex]);

  const [resultEvents, setResultEvents] = useState(null);
  useEffect(() => {
    if (searchEvents && searchIndex) {
      if (Object.keys(query).length === 0) {
        setResultEvents(searchEvents);
      } else {
        // console.log('query: ', query);
        setResultEvents(
          searchEvents.filter((event) =>
            facets.every((facet) => {
              const value = query[facet.id];
              return Array.isArray(value)
                ? value.some((val) => facet.match(event, val))
                : facet.match(event, value);
            })
          )
        );
      }
    } else {
      setResultEvents(null);
    }
  }, [searchEvents, searchIndex, query]);

  const resultFilters = useMemo(() => {
    const resultFacets = searchEvents ? calculateFacets(facets, searchEvents, query) : facets;
    return resultFacets;
  }, [searchEvents, query]);

  useEffect(() => {
    if (searchIndex) {
      const queryData = text ? { text, ...query } : query;
      const resultQueryString = stringifyQuery(queryData);
      const path = [location.pathname, resultQueryString].filter(Boolean).join('#');
      // console.log('resultQueryString, location.hash: ', resultQueryString, location.hash);
      if (resultQueryString !== location.hash.slice(1)) {
        // console.log('Navigate to new query: ', queryData);
        navigate(path);
      }
    }
  }, [location.pathname, location.hash, text, query, searchIndex]);

  const setText = useCallback((text) => dispatch({ type: 'CHANGE_TEXT', payload: text }), []);
  const setQuery = useCallback((query) => dispatch({ type: 'CHANGE_QUERY', payload: query }), []);

  return {
    text,
    setText,
    query,
    setQuery,
    resultEvents,
    resultFilters
  };
};

export default useSearchEvents;
