import React from 'react';
import { Redirect } from '@reach/router';
import { useAuth } from '../context/auth';
import Breadcrumbs from '../components/breadcrumbs';
import Seo from '../components/seo';

const breadcrumbs = [
  { label: 'Accesso', link: '/accesso/' },
  { label: 'Registrati', link: '/registrati/' },
  { label: 'Fatto!' }
];

const Fatto = () => {
  const { user } = useAuth();

  if (user) {
    return <Redirect to="/accesso" noThrow />;
  }

  return (
    <div className="container">
      <Seo title="Festival della Scienza online" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>Fatto!</h1>
          <p>Grazie per esserti registrato.</p>
          <p>
            Controlla la posta, eventualmente anche nella cartella di spam: ti abbiamo mandato una
            mail di conferma, e cliccando sull’indirizzo che troverai nella mail verrà completata la
            registrazione.
          </p>
          {/* <p>
            Se hai già un abbonamento per il Festival in presenza potrai fare il LOGIN al sito e inserire il numero identificativo del tuo biglietto 
            per avere accesso a tutti gli eventi online comodamente da casa.
          </p>
          <p>
            Se non hai ancora un abbonamento, potrai inserire i dati in un secondo momento 
            oppure acquistare un abbonamento dedicato per gli eventi in streaming dal nostro portale.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Fatto;
