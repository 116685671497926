import React from 'react';
import { Link } from '@reach/router';
import { isLive } from '../utils/helpers';
import { ReactComponent as Stream } from '../images/icons/stream.svg';
import { formatIntroduzione } from '../utils/helpers';

const checkBackgroundImage = (event) => {
  const bgImage =
    event.immagini && event.immagini.filter((immagine) => immagine.tipo === 'alta')[0];
  return bgImage ? { backgroundImage: `url(${bgImage.url})` } : {};
};
const EventoEvidenza = ({ event }) => {
  return (
    <>
      {event && (
        <div className="launch-evento" style={checkBackgroundImage(event)}>
          <div className="launch-evento__mask"></div>
          <div className="d-flex flex-column justify-content-center h-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-10">
                  <div className="launch-evento__content">
                    <div className="launch-evento__tag">
                      {isLive(event.orario_code) ? 'live ora' : 'in evidenza'}
                    </div>
                    {event.titolo && <h1 className="launch-evento__title">{event.titolo}</h1>}
                    {event.sottotitolo && (
                      <div className="launch-evento__text">{event.sottotitolo}</div>
                    )}
                    <div className="launch-evento__text">{formatIntroduzione(event)}</div>
                    {event.customCta ? (
                      <Link
                        to={event.customCta.link}
                        className="btn d-inline-flex align-items-center justify-content-between"
                      >
                        <span>{event.customCta.label}</span>
                        <Stream />
                      </Link>
                    ) : (
                      <Link
                        to={`/events/${event.id}`}
                        className="btn d-inline-flex align-items-center justify-content-between"
                      >
                        <span>Vai all'evento</span>
                        <Stream />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventoEvidenza;
