import React from 'react';
import { Link } from '@reach/router';
import conferenze from '../utils/conferenze';
import spettacoli from '../utils/spettacoli';
import { isLive } from '../utils/helpers';
import { formatIntroduzione } from '../utils/helpers';
import defaultImage from '../images/conferenze-spettacolo.png';
import { ReactComponent as Calendar } from '../images/icons/calendar-alt.svg';

const Event = ({
  data,
  cardVertical,
  hideBadge = false,
  showOrario = false,
  hideKeywords = false
}) => {
  const { id, titolo, sottotitolo, immagini, keywords, t_c, t_s, free, orario } = data;
  const live = isLive(data.orario_code);
  // const replay = !live && isPast(data.orario_code);
  return (
    <div id={`event-${id}`} className={`event${cardVertical ? ' event--vertical' : ' mb-4'}`}>
      <div className={`${cardVertical ? 'd-flex flex-column h-100' : 'row'}`}>
        <div className={`${cardVertical ? '' : 'col-md-7 col-lg-4'}`}>
          <Link to={`/events/${id}`}>
            <div className="event__img">
              {!hideBadge && (
                <div className="event__badge text-uppercase">
                  {/* {genere === '4' ? 'Evento Speciale' : conferenze[t_c] || spettacoli[t_s] || 'Conferenza'} */}
                  {conferenze[t_c] || spettacoli[t_s] || 'Conferenza'}
                </div>
              )}
              <img src={immagini && immagini[0] ? immagini[0].url : defaultImage} alt="" />
            </div>
          </Link>
        </div>
        <div className={`${cardVertical ? 'pt-4' : 'py-4 col-md-5 col-lg-6'}`}>
          <div className={`px-3 d-inline-flex flex-column h-100${cardVertical ? '' : ' px-md-0'}`}>
            <h3 className="event__title">
              <Link to={`/events/${id}`}>{titolo}</Link>
            </h3>
            <h4 className={`event__subtitle mb-3${cardVertical ? ' d-none' : ''}`}>
              {sottotitolo}
            </h4>
            {<div className="mb-3 event__intro">{formatIntroduzione(data)}</div>}
            {!hideKeywords && keywords ? (
              <div className={`${cardVertical ? 'd-none' : 'mt-auto d-none d-md-flex flex-wrap'}`}>
                {keywords.map((item) => (
                  <span key={item.code} className="event__keyword mr-2 mb-2 text-uppercase">
                    {item.word}
                  </span>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        {/* mostro la data solo se è tra quelle in dates.js*/}
        {(free || live || (orario && showOrario)) && (
          <div
            className={`${
              cardVertical
                ? 'd-none'
                : 'd-inline-flex flex-column flex-md-row col-lg-2 flex-lg-column'
            } py-3 py-md-4`}
          >
            {orario && showOrario && (
              <div className="event__date mb-auto px-3 pl-lg-0">
                <Calendar className="mr-2" />
                {orario.split(',').map((item, i) => (
                  <React.Fragment key={i}>
                    {item}
                    {i === 0 ? <br /> : null}
                  </React.Fragment>
                ))}
              </div>
            )}
            {free && (
              <div
                className={`event__free ml-auto align-self-start${
                  cardVertical ? '' : ' mt-lg-auto align-self-lg-end'
                }`}
              >
                Free
              </div>
            )}
            {/* {live && (
              <div
                className={`event__live align-self-start align-self-lg-end ml-auto${
                  free ? ' mt-3 mt-md-0 mt-lg-3 ml-md-3 ml-lg-auto' : ' mt-lg-auto'
                }`}
              >
                Live adesso
              </div>
            )} */}
            {/* {replay && (
              <div
                className={`event__live align-self-start align-self-lg-end ml-auto${
                  free ? ' mt-3 mt-md-0 mt-lg-3 ml-md-3 ml-lg-auto' : ' mt-lg-auto'
                }`}
              >
                Rivedi
              </div>
            )} */}
          </div>
        )}
        {/* <div className="col-lg-3 py-4 d-inline-flex flex-lg-column">
          {live ? (
            <div className="event__live mt-lg-auto ml-auto align-self-start align-self-lg-end">
              Live adesso
            </div>
          ): null}
          {replay ? (
            <Link to={`/events/${id}`} className="event__live mt-lg-auto ml-auto align-self-start align-self-lg-end">
              Rivedi
            </Link>
          ): null}
        </div> */}
        {!hideKeywords && keywords ? (
          <div className={`${cardVertical ? 'mt-auto' : 'col-sm-7 d-md-none'}`}>
            <div className="px-3">
              <div className={`mt-4 mb-3 d-flex flex-wrap${cardVertical ? '' : ' d-lg-none'}`}>
                {keywords.map((item) => (
                  <span key={item.code} className="event__keyword mr-2 mb-2 text-uppercase">
                    {item.word}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Event;
