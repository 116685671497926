import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { Location } from '@reach/router';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const OnRouteChangeWorker = ({ location }) => {
  const { trackPageView } = useMatomo();
  // Scrolla in alto al cambio di rotta (deve stare dopo il router)
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const pathRef = useRef();
  useEffect(() => {
    if (pathRef.current !== location.pathname) {
      pathRef.current = location.pathname;
      trackPageView();
    }
  }, [trackPageView, location.pathname]);

  return null;
};

const RouteChangeHandler = () => {
  return <Location>{({ location }) => <OnRouteChangeWorker location={location} />}</Location>;
};

export default RouteChangeHandler;
