import React from 'react';

const ToggleSwitch = ({
  checked,
  setChecked,
  onChange = (e) => setChecked(e.target.checked),
  round = true,
  inputClassName = '',
  sliderClassName = '',
  ...rest
}) => {
  const roudClass = round ? ' round' : '';
  const sliderClass = sliderClassName ? ` ${sliderClassName}` : '';

  return (
    <label className="toggle-switch" {...rest}>
      <input type="checkbox" checked={checked} onChange={onChange} className={inputClassName} />
      <span className={`slider${roudClass}${sliderClass}`}></span>
    </label>
  );
};

export default ToggleSwitch;
