import React from 'react';
import Seo from '../components/seo';
import Breadcrumbs from '../components/breadcrumbs';

const breadcrumbs = [
  { label: 'Stream Creator', link: '/stream-creator/' },
  { label: "Grazie per l'acquisto" }
];
const ConfermaAcquisto = () => {
  return (
    <div className="section">
      <div className="container">
        <Seo title="Festival della Scienza online" />
        <div className="row">
          <div className="col-12">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1>Grazie per l'acquisto</h1>
            <p>Acquisto completato correttamente.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfermaAcquisto;
