import React from 'react';
import { Link } from '@reach/router';
import { ReactComponent as Home } from '../images/icons/home.svg';

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <div className="breadcrumbs" role="navigation" aria-label="breadcrumb">
      <ol
        className="breadcrumbs__list"
        itemType="http://schema.org/BreadcrumbList"
        itemScope="itemscope"
      >
        <li
          className="breadcrumbs__item"
          itemType="http://schema.org/ListItem"
          itemScope="itemscope"
          itemProp="itemListElement"
        >
          <div aria-level="1" itemProp="item">
            <Link to="/" title="Homepage" className="breadcrumbs__link" itemProp="url">
              <Home />
            </Link>
          </div>
          <meta content="1" itemProp="position" />
        </li>
        {breadcrumbs.map((item, i) => (
          <li
            className="breadcrumbs__item"
            itemType="http://schema.org/ListItem"
            itemScope="itemscope"
            itemProp="itemListElement"
            key={i}
          >
            <div aria-level={i + 2} itemProp="item">
              {item.link ? (
                <Link
                  to={item.link}
                  title={item.label}
                  className="breadcrumbs__link"
                  itemProp="url"
                >
                  {item.label}
                </Link>
              ) : (
                <span itemProp="name">{item.label}</span>
              )}
            </div>
            <meta content={i + 2} itemProp="position" />
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Breadcrumbs;
