import React, { useCallback, useState, useEffect } from 'react';
import privacyPdf from '../images/privacy-policy.pdf';
import Breadcrumbs from '../components/breadcrumbs';

const breadcrumbs = [
  { label: 'Stream Creator', link: '/stream-creator/' },
  { label: 'Scarica il Programma' }
];

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateParameters = (formData) => {
  const { name, surname, email, privacy } = formData;
  const errors = {};
  if (!name) {
    errors.name = 'missingField';
  }
  if (!surname) {
    errors.surname = 'missingField';
  }
  if (!email) {
    errors.email = 'missingField';
  }
  if (email && !EMAIL_REGEX.test(email)) {
    errors.email = 'invalidEmail';
  }
  if (privacy === false) {
    errors.privacy = 'acceptPrivacy';
  }
  return errors;
};
const errorMessages = {
  invalidEmail: 'Formato email non valido',
  missingField: 'Il campo non può essere vuoto',
  acceptPrivacy: "È necessario accettare l'informativa sulla privacy"
};
const isFilled = (obj) => Object.values(obj).filter((val) => !!val).length;

const SyllabusForm = () => {
  const [attemptSubmit, setAttemptSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({ name: '', surname: '', email: '', privacy: false });
  const { name, surname, email, privacy } = formData;
  const onChange = useCallback((e) => {
    const { name, value, checked } = e.target;
    if (name === 'privacy') {
      setFormData((data) => ({ ...data, [name]: checked }));
    } else {
      setFormData((data) => ({
        ...data,
        [name]: name === 'email' ? value && value.toLowerCase() : value
      }));
    }
  }, []);
  const [errors, setErrors] = useState({});
  const validate = useCallback((formData) => {
    const errors = validateParameters(formData);
    setErrors(errors);
    return errors;
  }, []);
  const hasErrors = isFilled(errors);
  const reValidate = useCallback(() => {
    if (hasErrors) {
      validate(formData);
    }
  }, [hasErrors, formData, validate]);

  useEffect(() => {
    if (attemptSubmit) {
      validate(formData);
    }
  }, [attemptSubmit, formData, validate]);

  const signUp = useCallback(
    async (e) => {
      setError('');
      const errs = validate(formData);
      if (isFilled(errs)) {
        console.log('Invalid parameters');
        setAttemptSubmit(true);
        e.preventDefault();
        return;
      }
    },
    [formData, validate]
  );

  return (
    <div className="section section--has-min-height">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1>Scarica il Programma</h1>
            <form
              className="form"
              onSubmit={signUp}
              action="https://a2i8b8.emailsp.com/frontend/subscribe.aspx"
            >
              <div className="mb-3">
                <label className="form__label">
                  <div>Email*</div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={onChange}
                    placeholder=""
                    maxLength={320}
                    className={`form__field${errors['email'] ? ' form__field--error' : ''}`}
                    onBlur={reValidate}
                  />
                </label>
                {errors['email'] ? (
                  <div className="form__error">{errorMessages[errors['email']]}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="form__label">
                  <div>Nome*</div>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={onChange}
                    rel="1"
                    id="campo1"
                    placeholder=""
                    maxLength={320}
                    className={`form__field${errors['name'] ? ' form__field--error' : ''}`}
                    onBlur={reValidate}
                  />
                </label>
                {errors['name'] ? (
                  <div className="form__error">{errorMessages[errors['name']]}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="form__label">
                  <div>Cognome*</div>
                  <input
                    type="text"
                    name="surname"
                    value={surname}
                    onChange={onChange}
                    rel="2"
                    id="campo2"
                    placeholder=""
                    maxLength={320}
                    className={`form__field${errors['surname'] ? ' form__field--error' : ''}`}
                    onBlur={reValidate}
                  />
                </label>
                {errors['surname'] ? (
                  <div className="form__error">{errorMessages[errors['surname']]}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label
                  className={`form__label-checkbox${
                    errors['privacy'] ? ' form__label-checkbox--error' : ''
                  }`}
                >
                  Accetti l’informativa sulla{' '}
                  <a href={privacyPdf} target="_blank" rel="noopener noreferrer" className="">
                    Privacy
                  </a>{' '}
                  e l’iscrizione alla nostra newsletter che ti consentirà di rimanere aggiornato sui
                  nuovi contenuti. Potrai cancellare la tua iscrizione successivamente.
                  <input
                    type="checkbox"
                    name="privacy"
                    value={privacy}
                    onChange={onChange}
                    className="form__checkbox"
                  />
                  <span className="checkmark"></span>
                </label>
                {errors['privacy'] ? (
                  <div className="form__error">{errorMessages[errors['privacy']]}</div>
                ) : null}
              </div>
              {error ? (
                <div className="form__err-message">
                  <p>{error}</p>
                </div>
              ) : null}
              <div className="d-flex justify-content-end">
                <button type="submit" className="btn mb-3" disabled={hasErrors}>
                  Scarica
                </button>
              </div>
              <input type="hidden" name="list" value="8" />
              <input type="hidden" id="apgroup" name="apgroup" value="51" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyllabusForm;
