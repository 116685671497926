import React from 'react';
import Seo from '../components/seo';
import Breadcrumbs from '../components/breadcrumbs';

const breadcrumbs = [
  { label: 'Stream Creator', link: '/stream-creator/' },
  { label: 'Conferma iscrizione' }
];
const ConfermaIscrizioneStream = () => {
  return (
    <div className="section">
      <div className="container">
        <Seo title="Festival della Scienza online" />
        <div className="row">
          <div className="col-12">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1>Fatto!</h1>
            <p>Grazie per esserti iscritto.</p>
            <p>
              Controlla la posta, eventualmente anche nella cartella di spam: ti abbiamo mandato una
              mail di conferma, e cliccando sull’indirizzo che troverai nella mail verrà completata
              l'iscrizione.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfermaIscrizioneStream;
