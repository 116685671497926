import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import Breadcrumbs from '../components/breadcrumbs';
import Seo from '../components/seo';
import Link from '../components/link';
import { Redirect } from '@reach/router';
import { useAuth } from '../context/auth';

const breadcrumbs = [{ label: 'Acquisto completato' }];

const AcquistoCompletato = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (process.env.REACT_APP_FB_PIXEL_ID) {
      ReactPixel.track('Purchase', {});
    }
  }, []);

  if (!user) {
    return <Redirect to="/login" noThrow />;
  }

  return (
    <div className="container">
      <Seo title="Acquisto completato" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>Acquisto completato</h1>
          <Link to="/il-mio-abbonamento/">Vedi abbonamento</Link>
        </div>
      </div>
    </div>
  );
};

export default AcquistoCompletato;
