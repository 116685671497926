import React, { useCallback, useState, useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import { useAuth } from '../context/auth';
import { Redirect, navigate } from '@reach/router';
import Breadcrumbs from '../components/breadcrumbs';
import { getErrorMessage } from '../utils/cognito-error-messages';
import Seo from '../components/seo';
import privacyPdf from '../images/privacy-policy.pdf';

const breadcrumbs = [{ label: 'Accesso', link: '/accesso/' }, { label: 'Registrati' }];
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/;
const validateParameters = (formData) => {
  const { name, surname, email, email2, password, password2, privacy } = formData;
  const errors = {};
  if (!name) {
    errors.name = 'missingField';
  }
  if (!surname) {
    errors.surname = 'missingField';
  }
  if (!email) {
    errors.email = 'missingField';
  }
  if (!email2) {
    errors.email2 = 'missingField';
  }
  if (!password) {
    errors.password = 'missingField';
  }
  if (!password2) {
    errors.password2 = 'missingField';
  }
  if (email && !EMAIL_REGEX.test(email)) {
    errors.email = 'invalidEmail';
  }
  if (email && email2 && email !== email2) {
    errors.email2 = 'emailMismatch';
  }
  if (password && !PASSWORD_REGEX.test(password)) {
    errors.password = 'invalidPassword';
  }
  if (password && password2 && password !== password2) {
    errors.password2 = 'passwordMismatch';
  }
  if (privacy === false) {
    errors.privacy = 'acceptPrivacy';
  }
  return errors;
};
const errorMessages = {
  invalidEmail: 'Formato email non valido',
  invalidPassword: 'Formato password non valido',
  passwordMismatch: 'La password non è uguale',
  emailMismatch: "L'email non è uguale",
  missingField: 'Il campo non può essere vuoto',
  acceptPrivacy: "È necessario accettare l'informativa sulla privacy"
};
const isFilled = (obj) => Object.values(obj).filter((val) => !!val).length;

const Registrati = () => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [attemptSubmit, setAttemptSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    email2: '',
    password: '',
    password2: '',
    privacy: false
  });
  const { name, surname, email, email2, password, password2, privacy } = formData;
  const onChange = useCallback((e) => {
    const { name, value, checked } = e.target;
    if (name === 'privacy') {
      setFormData((data) => ({ ...data, [name]: checked }));
    } else {
      setFormData((data) => ({
        ...data,
        [name]: name === 'email' || name === 'email2' ? value && value.toLowerCase() : value
      }));
    }
  }, []);
  const [errors, setErrors] = useState({});
  const validate = useCallback((formData) => {
    const errors = validateParameters(formData);
    setErrors(errors);
    return errors;
  }, []);
  const hasErrors = isFilled(errors);
  const reValidate = useCallback(() => {
    if (hasErrors) {
      validate(formData);
    }
  }, [hasErrors, formData, validate]);

  useEffect(() => {
    if (attemptSubmit) {
      validate(formData);
    }
  }, [attemptSubmit, formData, validate]);

  const signUp = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      setError('');
      const errs = validate(formData);
      if (isFilled(errs)) {
        console.log('Invalid parameters');
        setAttemptSubmit(true);
        return;
      }
      setLoading(true);
      try {
        const { user } = await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            name: name,
            family_name: surname,
            'custom:first_name': name,
            'custom:last_name': surname
          }
        });
        console.log(user);
        setError('');
        navigate('/registrati/fatto');
      } catch (err) {
        console.log('error signing up:', err);
        setError(getErrorMessage(err));
      }
      setLoading(false);
    },
    [name, surname, email, password, formData, validate]
  );

  if (user) {
    return <Redirect to="/" noThrow />;
  }
  return (
    <div className="container">
      <Seo title="Registrati - Festival della Scienza online" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>Registrati</h1>
          {/* <p className="mb-4">
            Attenzione: Il servizio per l'acquisto di nuovi abbonamenti è momentaneamente sospeso, 
            il servizio sarà ripristinato non appena possibile
          </p> */}
          <form className="form" onSubmit={signUp}>
            <div className="mb-3">
              <label className="form__label">
                <div>Nome*</div>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={onChange}
                  placeholder=""
                  disabled={loading}
                  maxLength={320}
                  className={`form__field${errors['name'] ? ' form__field--error' : ''}`}
                  onBlur={reValidate}
                />
              </label>
              {errors['name'] ? (
                <div className="form__error">{errorMessages[errors['name']]}</div>
              ) : null}
            </div>
            <div className="my-3">
              <label className="form__label">
                <div>Cognome*</div>
                <input
                  type="text"
                  name="surname"
                  value={surname}
                  onChange={onChange}
                  placeholder=""
                  disabled={loading}
                  maxLength={320}
                  className={`form__field${errors['surname'] ? ' form__field--error' : ''}`}
                  onBlur={reValidate}
                />
              </label>
              {errors['surname'] ? (
                <div className="form__error">{errorMessages[errors['surname']]}</div>
              ) : null}
            </div>
            <div className="my-3">
              <label className="form__label">
                <div>Email*</div>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  placeholder=""
                  disabled={loading}
                  maxLength={320}
                  className={`form__field${errors['email'] ? ' form__field--error' : ''}`}
                  onBlur={reValidate}
                />
              </label>
              {errors['email'] ? (
                <div className="form__error">{errorMessages[errors['email']]}</div>
              ) : null}
            </div>
            <div className="my-3">
              <label className="form__label">
                <div>Conferma Email*</div>
                <input
                  type="email"
                  name="email2"
                  value={email2}
                  onChange={onChange}
                  placeholder=""
                  disabled={loading}
                  maxLength={320}
                  className={`form__field${errors['email2'] ? ' form__field--error' : ''}`}
                  onBlur={reValidate}
                />
              </label>
              {errors['email2'] ? (
                <div className="form__error">{errorMessages[errors['email2']]}</div>
              ) : null}
            </div>
            <div className="my-3">
              <label className="form__label">
                <div>Password*</div>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  placeholder=""
                  disabled={loading}
                  maxLength={320}
                  className={`form__field${errors['password'] ? ' form__field--error' : ''}`}
                  onBlur={reValidate}
                />
              </label>
              <div className="text-small">
                <p>
                  La password deve essere di almeno 8 caratteri, deve avere almeno una maiuscola,
                  una minuscola, un numero e un simbolo
                </p>
              </div>
              {errors['password'] ? (
                <div className="form__error">{errorMessages[errors['password']]}</div>
              ) : null}
            </div>
            <div className="my-3">
              <label className="form__label">
                <div>Conferma password*</div>
                <input
                  type="password"
                  name="password2"
                  value={password2}
                  onChange={onChange}
                  placeholder=""
                  disabled={loading}
                  maxLength={320}
                  className={`form__field${errors['password2'] ? ' form__field--error' : ''}`}
                  onBlur={reValidate}
                />
              </label>
              {errors['password2'] ? (
                <div className="form__error">{errorMessages[errors['password2']]}</div>
              ) : null}
            </div>
            <div className="my-3">
              <label
                className={`form__label-checkbox${
                  errors['privacy'] ? ' form__label-checkbox--error' : ''
                }`}
              >
                Creando un account, accetti l’informativa sulla{' '}
                <a href={privacyPdf} target="_blank" rel="noopener noreferrer" className="">
                  Privacy
                </a>{' '}
                e l’iscrizione alla nostra newsletter che ti consentirà di rimanere aggiornato sui
                nuovi contenuti. Potrai cancellare la tua iscrizione successivamente.
                <input
                  type="checkbox"
                  name="privacy"
                  value={privacy}
                  onChange={onChange}
                  disabled={loading}
                  className="form__checkbox"
                />
                <span className="checkmark"></span>
              </label>
              {errors['privacy'] ? (
                <div className="form__error">{errorMessages[errors['privacy']]}</div>
              ) : null}
            </div>
            {error && !loading ? (
              <div className="form__err-message">
                <p>{error}</p>
              </div>
            ) : null}
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn mb-3" disabled={loading || hasErrors}>
                {loading ? (
                  <div className="d-flex align-items-center">
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Caricamento...
                  </div>
                ) : (
                  'Registrati'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Registrati;
