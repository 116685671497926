import React, { useCallback, useState } from 'react';

const Faq = ({ title, className, startExpanded = false, children }) => {
  const [expanded, setExpanded] = useState(startExpanded);
  const toggleExpanded = useCallback(() => setExpanded((value) => !value), []);

  return (
    <div className={`faq${expanded ? ' faq--expanded' : ''}${className ? ` ${className}` : ''}`}>
      <div className="faq__header py-2 pr-3 d-flex justify-content-between align-items-center">
        <div className="faq__title">{title}</div>
        <button
          className="toggle ml-2"
          aria-label={expanded ? 'Chiudi' : 'Apri'}
          aria-expanded={expanded}
          onClick={toggleExpanded}
        ></button>
      </div>
      <hr></hr>
      <div className="faq__body pt-3" aria-hidden={!expanded}>
        {children}
      </div>
    </div>
  );
};

export default Faq;
