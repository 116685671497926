import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import { useAuth } from '../context/auth';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { user } = useAuth();
  if (!user && location.pathname !== `/login`) {
    // If we’re not logged in, redirect to the login page.
    console.log('Not logged: navigate to login');
    return <Redirect to="/login" state={{ referer: location }} noThrow />;
  }
  return <Component {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired
};

export default PrivateRoute;
