import React, { useCallback, useEffect, useState, useRef } from 'react';
import useApi from '../context/useApi';

const AcquistaAbbonamento = () => {
  const { prepareForm } = useApi();

  const [formFields, setFormFields] = useState(null);
  const [formUrl, setFormUrl] = useState(null);
  const formRef = useRef();
  const [loadingForm, setLoadingForm] = useState(false);

  const submit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoadingForm(true);
      const { data } = await prepareForm();
      const { formData, formUrl } = data;
      setFormUrl(formUrl);
      setFormFields(
        Object.keys(formData).reduce(
          (fields, key) => fields.concat([{ name: key, value: formData[key] }]),
          []
        )
      );
    },
    [prepareForm]
  );

  useEffect(() => {
    if (formFields && formUrl) {
      formRef.current.submit();
    }
  }, [formFields, formUrl]);

  return (
    <form ref={formRef} onSubmit={submit} method="post" action={formUrl} className="mt-auto">
      {formFields &&
        formFields.map((field) => (
          <input type="hidden" name={field.name} value={field.value} key={field.name} />
        ))}
      <button className="btn" type="submit">
        {loadingForm ? (
          <div className="d-flex align-items-center">
            <span
              className="spinner-border spinner-border-sm mr-1"
              role="status"
              aria-hidden="true"
            ></span>
            Acquista abbonamento...
          </div>
        ) : (
          'Acquista abbonamento'
        )}
      </button>
    </form>
  );
};

export default AcquistaAbbonamento;
