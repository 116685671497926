export const sortByOrarioCode = (evA, evB) => {
  const { orario_code: codeA } = evA;
  const { orario_code: codeB } = evB;
  if (!codeA || !codeB) return 0;
  const orarioA = `${codeA.substring(2, 4)}${codeA.substring(0, 2)}${codeA.substring(4)}`;
  const orarioB = `${codeB.substring(2, 4)}${codeB.substring(0, 2)}${codeB.substring(4)}`;
  return orarioA < orarioB ? -1 : 1;
};

export const sortAlphabetical = (array, prop) => {
  return array.sort((a, b) => (a[prop].toLowerCase() < b[prop].toLowerCase() ? -1 : 1));
};
