export const parseQueryString = (queryString, allowedKeys) => {
  const urlSearchParams = new URLSearchParams(queryString || '');
  const query = {};
  for (let [key, value] of urlSearchParams.entries()) {
    if (!allowedKeys || allowedKeys.includes(key)) {
      query[key] = query[key]
        ? Array.isArray(query[key])
          ? query[key].concat(value)
          : [query[key], value]
        : value;
    }
  }
  return query;
};

export const stringifyQuery = (query) => {
  const params = new URLSearchParams();
  for (let key in query) {
    if (Array.isArray(query[key])) {
      query[key].forEach((val) => params.append(key, val));
    } else {
      params.append(key, query[key]);
    }
  }
  params.sort();
  return params.toString();
};
