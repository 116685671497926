const tipologie = [
  {
    value: '0',
    label: 'I generi'
  },
  {
    value: 'conf',
    label: 'Conferenze'
  },
  {
    value: 'spet',
    label: 'Spettacoli'
  }
];

export default tipologie;
