import React from 'react';
import { Redirect } from '@reach/router';
import Breadcrumbs from '../components/breadcrumbs';
import { useAuth } from '../context/auth';
import Seo from '../components/seo';
import AcquistaAbbonamento from '../components/acquista-abbonamento';
import ValidateTicket from '../components/validate-ticket';
// import Link from '../components/link';
import bannerFestival from '../images/festival_2024.png';

const breadcrumbs = [{ label: 'Accesso' }];

const Abbonamento = () => {
  const { user, userData } = useAuth();

  if (userData && userData.abbonamento && !userData.expireDate) {
    return <Redirect to="/i-miei-dati" noThrow />;
  }

  if (!user) {
    return <Redirect to="/login" noThrow />;
  }

  if (!userData) {
    return null;
  }

  return (
    <div className="container">
      <Seo title="Abbonamento - Festival della Scienza online" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h1>
            {userData.expireDate
              ? 'Il tuo abbonamento è scaduto!'
              : 'Grazie per esserti registrato!'}
          </h1>
        </div>
      </div>
      <div className="row mb-5">
        {/* <div className="col-lg-6">
          <div className="option-box h-100 w-100 d-inline-flex flex-column">
            <h2>Scopri il nostro palinsesto</h2>
            <p>Lectio magistralis, incontri, spettacoli, eventi speciali</p>
            <Link to="/tutti-video" className="btn align-self-start mt-auto">
              Vai al palinsesto
            </Link>
          </div>
        </div> */}
        <div className="col-lg-6 mt-4 mt-lg-0">
          <div className="option-box h-100 w-100 d-inline-flex flex-column">
            <h2>Hai già un abbonamento Festival?</h2>
            <p className="mb-4">
              L'abbonamento al Festival in presenza, include l'abbonamento alla piattaforma.
              <br />
              Inserisci qui il numero identificativo del tuo biglietto (barcode)
            </p>
            <ValidateTicket className="mt-auto" />
          </div>
        </div>
        <div className="col-lg-6 mt-4 mt-lg-0">
          <div className="option-box h-100 w-100 d-inline-flex flex-column">
            <h2>Acquista l'abbonamento online</h2>
            <p className="mb-4">
              Se non hai un abbonamento al Festival acquista l'abbonamento alla piattaforma per
              vedere tutti i contenuti in streaming e on-demand
            </p>
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mt-auto">
              <span className="option-box__price mb-3 mb-sm-0">10,00 €</span>
              <AcquistaAbbonamento />
            </div>
            {/* <Link to="/codice-biglietto/" className="btn align-self-start mt-3">Acquista abbonamento</Link> */}
          </div>
        </div>

        <div className="col-12 mt-5">
          <div className="option-box p-0 d-flex flex-column align-items-center flex-lg-row">
            <div className="option-box__banner-img">
              <img
                src={bannerFestival}
                alt="Festival della Scienza Genova, 21 Ottobre - 1 Novermbre 2021"
              />
            </div>
            <div className="option-box__banner-text d-flex flex-column">
              <h2>Festival della Scienza 2024</h2>
              <p>
                Il Festival della Scienza è in programma a Genova da giovedì 24 ottobre a domenica 3
                novembre 2024. Per 11 giorni Genova apre le porte delle sue meravigliose sedi per
                ospitare un programma ricco di conferenze, mostre, laboratori, spettacoli e altri
                eventi.
              </p>
              {/* <p>
                <span>Scopri </span>
                <a
                  href="http://www.festivalscienza.it/site/home/programma.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  il programma del festival in presenza
                </a>
                <span> e la </span>
                <Link to="/festival-2021">sezione online in streaming e on demand</Link>.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abbonamento;
