import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { ReactComponent as ClearIcon } from '../images/icons/redo-light.svg';

const MAX_VALUES = 10;
const Facet = ({ facet, onChange }) => {
  const [showMore] = useState(true);
  const [expanded, setExpanded] = useState(() => facet.values?.some((item) => item.active));
  const toggleFacet = useCallback(() => setExpanded((value) => !value), []);
  // const toggleMore = useCallback(() => setShowMore((current) => !current), []);
  return (
    <div className="facets__facet" key={facet.id}>
      <button
        className={classNames('facets__facet-name', {
          open: expanded
        })}
        onClick={toggleFacet}
      >
        {facet.name}
      </button>
      <div
        className={classNames('facets__facet-wrapper', {
          open: expanded
        })}
      >
        <ul className={'facets__facet-filter'}>
          {(showMore ? facet.values : facet.values.slice(0, MAX_VALUES)).map((item) => (
            <li key={item.value} data-key={item.value}>
              <label
                className={classNames('checkbox', 'facets__label', {
                  disabled: !item.active && !item.count,
                  active: item.active
                })}
              >
                <span>
                  <span className="facets__value mx-1">{item.label}</span>
                  <span className="facets__count">{`(${item.count})`}</span>
                </span>
                <input
                  type="checkbox"
                  className="checkbox__input"
                  data-facet={facet.id}
                  checked={!!item.active}
                  disabled={!item.active && !item.count}
                  name={item.value}
                  onChange={onChange}
                />
                <span className="checkbox__mark"></span>
              </label>
            </li>
          ))}
        </ul>
        {/* {facet.values.length > MAX_VALUES ? (
          <button onClick={toggleMore} className="facets__show-more">
            <span>{showMore ? 'Mostra di meno' : 'Mostra di più'}</span>
          </button>
        ) : null} */}
      </div>
    </div>
  );
};

const Filters = ({ data, onChange, onClear }) => {
  const facets = data.filter(
    (facet) => !!facet.values?.some((item) => item.active || item.count > 0)
  );
  return facets.length ? (
    <div className="facets">
      <button className="facets__reset mb-3" onClick={onClear}>
        <ClearIcon />
        <span className="ml-2">Resetta filtri</span>
      </button>
      {facets.map((facet) => (
        <Facet facet={facet} onChange={onChange} key={facet.id} />
      ))}
    </div>
  ) : null;
};

export default Filters;
