import { Redirect } from '@reach/router';
import classNames from 'classnames';
import React, { useState, useCallback, useEffect } from 'react';
import { useAuth } from '../context/auth';
import useApi from '../context/useApi';

const ValidateTicket = ({ className }) => {
  const [ticketCode, setTicketCode] = useState('');
  const onChangeCode = useCallback((e) => {
    setErrorTicket(null);
    setTicketCode(e.target.value);
  }, []);

  const { userData, updateUserData } = useAuth();
  const { validateTicket } = useApi();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorTicket, setErrorTicket] = useState(null);

  const validate = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      if (!ticketCode) return;
      if (ticketCode.length < 13) {
        setErrorTicket('Il codice biglietto deve essere di almeno 13 cifre');
        return;
      }
      setErrorTicket(null);
      setLoading(true);
      try {
        const { data } = await validateTicket(ticketCode);
        console.log('Codice biglietto validato: ', data.userData);
        setSuccess(true);
      } catch (err) {
        console.error('Error in validateTicket: ', err);
        setErrorTicket('Codice biglietto non valido');
      }
      setLoading(false);
    },
    [validateTicket, ticketCode]
  );

  const abbonamento = userData && !userData.expireDate && userData.abbonamento;

  useEffect(() => {
    if (success && !abbonamento) {
      updateUserData();
    }
  }, [success, abbonamento, updateUserData]);

  if (abbonamento && !success) {
    return <Redirect to="/" noThrow />;
  }

  return (
    <div className={classNames('align-self-md-start', className)}>
      {success ? (
        <div className="p-2">
          <h3>Codice biglietto validato con successo</h3>
          <p>Ora hai accesso a tutti gli eventi in streaming.</p>
        </div>
      ) : (
        <form
          onSubmit={validate}
          className="d-flex flex-column flex-md-row align-items-center align-items-md-start"
        >
          <div className="d-inline-flex flex-column align-items-start align-self-stretch">
            <input
              className={classNames('form__field form__field--ticket align-self-md-start mt-0', {
                'form__field--error': errorTicket
              })}
              value={ticketCode}
              onChange={onChangeCode}
            />
            {errorTicket ? <div className="form__error mt-2">{errorTicket}</div> : null}
          </div>
          <button
            type="submit"
            className="btn mt-4 mt-md-0 ml-md-3 flex-shrink-0"
            disabled={loading || !ticketCode}
          >
            {loading ? (
              <div className="d-flex align-items-center">
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Caricamento...
              </div>
            ) : (
              'Avanti'
            )}
          </button>
        </form>
      )}
    </div>
  );
};

export default ValidateTicket;
