import React from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';
import { ReactComponent as Calendar } from '../images/icons/calendar-alt.svg';

const LaunchBox = ({ box, index, logged }) => {
  return (
    <div
      key={index}
      className={classNames(`launch-box`, {
        [`launch-box--${box.name}`]: box.name,
        'no-bg': logged
      })}
      style={box.background ? { backgroundImage: `url(${box.background})` } : {}}
    >
      <div
        className={classNames({
          'launch-box__mask': !logged
        })}
      ></div>
      <div className="d-flex flex-column justify-content-center h-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <div className="launch-box__content">
                {box.tag && <div className="launch-box__tag">{box.tag}</div>}
                {box.title && <h1 className="launch-box__title">{box.title}</h1>}
                {box.logo && (
                  <Link to={`/${box.name}`}>
                    <img src={box.logo} alt="logo" className="launch-box__logo" />
                  </Link>
                )}
                {box.subtitle && <h2 className="launch-box__subtitle">{box.subtitle}</h2>}
                <div className="launch-box__text">{box.text}</div>
                {!logged && box.btn && (
                  <Link
                    to={box.btn.link}
                    className={`btn align-self-start launch-box__btn mr-4${
                      box.btn.label === 'Scopri' ? ' btn--secondary' : ''
                    }`}
                  >
                    {box.btn.label}
                  </Link>
                )}
                {box.btnLogged && (
                  <Link
                    to={box.btnLogged.link}
                    className={`btn align-self-start launch-box__btn${
                      box.btnLogged.label === 'Scopri' ? ' btn--secondary' : ''
                    }`}
                  >
                    {box.btnLogged.label}
                  </Link>
                )}
                {box.date && (
                  <div className="launch-box__date d-flex-align-items-end">
                    <Calendar />
                    <span className="section__date">{box.date}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaunchBox;
