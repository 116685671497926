import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import conferenze from './conferenze';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

const parseAge = (age) => {
  const matches = /(\d+)\+/.exec(age);
  return matches ? parseInt(matches[1]) : parseInt(age);
};
export const formatTarget = (target) => {
  if (!target || typeof target !== 'string') return target;
  const ages = target.split(',');
  ages.sort((a, b) =>
    Math.min(...a.split('-').map(parseAge)) < Math.min(...b.split('-').map(parseAge)) ? -1 : 1
  );
  const startAge = ages[0].split('-')[0];
  const endAges = ages[ages.length - 1].split('-');
  return `Da ${startAge} ${
    /(\d+)\+/.test(endAges[0]) ? 'anni' : `a ${endAges[endAges.length - 1]} anni`
  }`;
};

export const formatCollaboratori = (collaboratori) => {
  if (!collaboratori || typeof collaboratori !== 'string') return collaboratori;
  return collaboratori
    .split('#')
    .map((item) => item.split('&')[0])
    .join(', ');
};

export const formatIntroduzione = (event) => {
  if (!event) return null;
  if (event.relatori?.length && (event.tipologia_conferenza || event.genere === 'Conferenza')) {
    const relatoriBase = event.relatori
      .filter((relatore) => !['Introduttore', 'Moderatore'].includes(relatore.tipo_intervento))
      .map((relatore) => `${relatore.nome} ${relatore.cognome}`)
      .join(', ');
    const altriRelatori = event.relatori
      .filter((relatore) => ['Introduttore', 'Moderatore'].includes(relatore.tipo_intervento))
      .map(
        (relatore) =>
          `${relatore.tipo_intervento === 'Introduttore' ? 'introduce: ' : 'modera '}${
            relatore.nome
          } ${relatore.cognome}`
      )
      .join(', ');
    const altriRelatoriFormatted =
      altriRelatori.split('modera').length > 2 &&
      altriRelatori.replace(/(\b, modera\b)(?!.*\1)/g, ',');
    return `${conferenze[event.tipologia_conferenza || event.t_c] || event.genere} con ${[
      relatoriBase,
      altriRelatoriFormatted || altriRelatori
    ]
      .filter((item) => !!item)
      .join(', ')}`;
  } else if (
    (event.tipologia_spettacolo || event.genere === 'Evento speciale') &&
    event.partecipanti
  ) {
    return event.partecipanti;
  }
  return null;
};

export const isLive = (orario_code) => {
  if (!orario_code) return false;
  try {
    const date = orario_code.split('\n')[0];
    if (date) {
      const matches = /(\d{4})\[(\d\d:\d\d)-(\d\d:\d\d)\]/.exec(date);
      if (!matches) return false;
      const [, dt, start, end] = matches;
      const startDate = dayjs(`${dt} ${start}`, 'DDMM HH:mm');
      const endDate = dayjs(`${dt} ${end}`, 'DDMM HH:mm');
      return !!dayjs().isBetween(startDate, endDate);
    }
    return false;
  } catch (err) {
    console.error('Errore nel calcolo isLive: ', err);
    return false;
  }
};

export const isPast = (orario_code) => {
  if (!orario_code) return false;
  try {
    const date = orario_code.split('\n')[0];
    if (date) {
      const [, dt, , end] = /(\d{4})\[(\d\d:\d\d)-(\d\d:\d\d)\]/.exec(date);
      const endDate = dayjs(`${dt} ${end}`, 'DDMM HH:mm');
      const now = dayjs.tz(new Date(), 'Europe/Rome');
      return !!endDate.isBefore(now);
    }
    return false;
  } catch (err) {
    console.error('Errore nel calcolo isPast: ', err);
    return false;
  }
};

export const dateInfo = (event) => {
  if (!event.eventi_organizzazione || !event.eventi_organizzazione.orario_code) return false;
  try {
    const date = event.eventi_organizzazione.orario_code.split('\n')[0];
    if (date) {
      const [, dt, start] = /(\d{4})\[(\d\d:\d\d)-(\d\d:\d\d)\]/.exec(date);
      const startDate = dayjs(`${dt} ${start}`, 'DDMM HH:mm');
      const now = dayjs.tz(new Date(), 'Europe/Rome');
      console.log('now: ', now.format('DD-MM-YYYY HH:mm:ss'));
      return {
        isToday: dayjs.tz(new Date(), 'Europe/Rome').date() === startDate.date(),
        isStarted: !!startDate.isBefore(now)
      };
    }
    return false;
  } catch (err) {
    console.error('Errore nel calcolo dateInfo: ', err);
    return false;
  }
};

export const getCookie = (name) =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
