import React from 'react';
import Seo from '../components/seo';
import Breadcrumbs from '../components/breadcrumbs';

const breadcrumbs = [{ label: 'Stream Creator', link: '/stream-creator/' }, { label: 'Errore' }];

const REACT_APP_LINK_SYLLABUS = process.env.REACT_APP_LINK_SYLLABUS;
const ErroreStream = () => {
  return (
    <div className="section">
      <div className="container">
        <Seo title="Festival della Scienza online" />
        <div className="row">
          <div className="col-12">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1>Errore</h1>
            <p>Attenzione: l'indirizzo risulta già iscritto.</p>
            <p>
              Clicca{' '}
              <a href={REACT_APP_LINK_SYLLABUS} target="_blank" rel="noopener noreferrer">
                qui
              </a>{' '}
              per scaricare il programma.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErroreStream;
