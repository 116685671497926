import { Link } from '@reach/router';
import React from 'react';
import privacyPdf from '../images/privacy-policy.pdf';
// import { Link } from '@reach/router'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column flex-md-row justify-content-center align-items-center">
            <a
              href="http://www.festivalscienza.it/"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-4"
            >
              Chi siamo
            </a>
            <Link to="/cookie-policy" className="mx-4">
              Cookie Policy
            </Link>
            <a href={privacyPdf} target="_blank" rel="noopener noreferrer" className="mx-4">
              Privacy
            </a>
            <a
              href="https://www.ariadnedigital.it"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-4"
            >
              Credits
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
