import React from 'react';
import { Redirect } from '@reach/router';
import useAllEvents from '../hooks/use-all-event';

const LocalEvents = ({ year, localId }) => {
  const list = useAllEvents();
  if (list?.events && !list.loading) {
    const event = list?.events?.find(
      (event) => event.origine === year && event.localId === localId
    );
    if (event) {
      return <Redirect to={`/events/${event.id}`} noThrow />;
    }
    return <Redirect to="/404" noThrow />;
  }
  return (
    <div className="row">
      <div className="col-12 text-center">
        <div className="d-inline-flex align-items-center">
          <span className="spinner-border mr-1" role="status" aria-hidden="true"></span>
        </div>
      </div>
    </div>
  );
};

export default LocalEvents;
