import { useEffect, useCallback, useState } from 'react';
import axios from 'axios';

const REACT_APP_EVENTS_URL = process.env.REACT_APP_EVENTS_URL;

const useAllEvents = (postProcess) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState(null);
  const loadEvents = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${REACT_APP_EVENTS_URL}/allEvents.json`);
      const eventsList = data.rows.map((row) => {
        return {
          ...row,
          keywords:
            row.parole_chiave &&
            row.parole_chiave.split('#').map((item) => {
              const [word, code] = item.split('&');
              return { word, code };
            })
        };
      });
      const resEvents = postProcess ? postProcess(eventsList) : eventsList;
      setEvents(resEvents);
    } catch (err) {
      console.error('Error loading events: ', err);
    }
    setLoading(false);
  }, [postProcess]);

  useEffect(() => {
    if (!events) {
      loadEvents();
    }
  }, [loadEvents, events]);

  return { events, loading };
};

export default useAllEvents;
