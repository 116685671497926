import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../context/auth';
import useApi from '../context/useApi';
import Breadcrumbs from '../components/breadcrumbs';
import Seo from '../components/seo';

const breadcrumbs = [{ label: 'Hai un codice coupon?' }];

const CodiceCoupon = () => {
  const { userData, updateUserData } = useAuth();
  const { validateTicket } = useApi();

  const [ticketCode, setTicketCode] = useState('');
  const onChangeCode = useCallback((e) => setTicketCode(e.target.value), []);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorTicket, setErrorTicket] = useState(null);
  const validate = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      if (!ticketCode) return;
      setErrorTicket(null);
      setLoading(true);
      try {
        const { data } = await validateTicket(ticketCode);
        console.log('Codice coupon validato: ', data.userData);
        setSuccess(true);
      } catch (err) {
        console.error('Error in validateTicket: ', err);
        setErrorTicket('Codice coupon non valido');
      }
      setLoading(false);
    },
    [validateTicket, ticketCode]
  );

  const abbonamento = userData && userData.abbonamento;

  useEffect(() => {
    if (success) {
      updateUserData();
    }
  }, [success, abbonamento, updateUserData]);

  return (
    <div className="container my-5">
      <Seo title="Codice coupon - Festival della Scienza online" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      {success ? (
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1>Codice coupon validato con successo</h1>
            <p>Ora hai accesso a tutti gli eventi in streaming.</p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1>Hai un codice coupon?</h1>
            <div className="option-box mt-5">
              <form onSubmit={validate}>
                <h2>Inserisci il tuo codice</h2>
                <input
                  className={`form__field${errorTicket ? ' form__field--error' : ''} mt-4`}
                  value={ticketCode}
                  onChange={onChangeCode}
                />
                {errorTicket ? <div className="form__error mt-2">{errorTicket}</div> : null}
                <div className="mt-3 d-flex justify-content-end">
                  <button type="submit" className="btn" disabled={loading || !ticketCode}>
                    {loading ? (
                      <div className="d-flex align-items-center">
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Caricamento...
                      </div>
                    ) : (
                      'Avanti'
                    )}
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="option-box mt-5">
              <h2>Non ho un codice biglietto</h2>
              <form ref={formRef} onSubmit={submit} method="post" action={formUrl}>
                <div className="mt-4 d-flex">
                  <span className="mr-3">Acquista l’abbonamento agli eventi in streaming 10,00 €</span>
                  {formFields && formFields.map(field => <input type="hidden" name={field.name} value={field.value} key={field.name} />)}
                  <button className="btn" type="submit">
                    {loadingForm ? (
                      <div className="d-flex align-items-center">
                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Acquista...
                      </div>
                    ) : 'Acquista'}
                  </button>
                </div>
              </form>
              <p>
                Attenzione: Il servizio per l'acquisto di nuovi abbonamenti è momentaneamente sospeso, 
                il servizio sarà ripristinato non appena possibile
              </p>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default CodiceCoupon;
