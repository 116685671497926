const spettacoli = {
  1: 'Spettacolo teatrale',
  2: 'Documentario',
  3: 'Spettacolo musicale',
  4: 'Teatro di narrazione scientifica',
  5: 'Concerto',
  6: 'Film',
  7: 'Monologo',
  8: 'Teatro per ragazzi'
};

export default spettacoli;
