import React from 'react';
import Header from './header';
import Footer from './footer';
import { Location } from '@reach/router';
// import Sponsor from './sponsor';

const Layout = ({ children, location }) => {
  return (
    <div id="app-container" className={`app`}>
      <Header />
      <div className={`app__content${location.pathname === '/' ? ' pt-0' : ''}`}>{children}</div>
      <Footer />
      {/* <Sponsor /> */}
    </div>
  );
};

const LayoutWrapper = (props) => {
  return <Location>{({ location }) => <Layout {...props} location={location} />}</Location>;
};

export default LayoutWrapper;
