import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Event from '../components/event';
import Filters from '../components/filters';
import Seo from '../components/seo';
import { useAuth } from '../context/auth';
import useAllEvents from '../hooks/use-all-event';
import { updateQuery } from '../utils/facets';
import { sortAlphabetical } from '../utils/sort';
import useSearchEvents from '../hooks/use-search-events';
import { ReactComponent as FiltersIcon } from '../images/icons/sliders-h-light.svg';
import { ReactComponent as SearchIcon } from '../images/icons/search-light.svg';

const filterAndSort = (events) =>
  sortAlphabetical(events /*.filter((event) => event.origine !== '2024')*/, 'titolo');

const TuttiVideo = ({ location }) => {
  const { user, userData } = useAuth();

  const { events } = useAllEvents(filterAndSort);
  const { text, setText, query, setQuery, resultEvents, resultFilters } = useSearchEvents(
    events,
    location
  );
  const clearSearch = useCallback(() => setText(''), [setText]);
  const searchInputRef = useRef();

  const [searchVisible, setSearchVisible] = useState(!!text);
  const showSearch = useCallback(() => {
    setSearchVisible(true);
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const searchWrapperRef = useRef();
  useEffect(() => {
    if (searchVisible) {
      const listener = (event) => {
        const wrapperEl = searchWrapperRef.current;
        if (
          event.target !== wrapperEl &&
          wrapperEl &&
          !wrapperEl.contains(event.target) &&
          searchInputRef.current &&
          !searchInputRef.current.value
        ) {
          setSearchVisible(false);
        }
      };
      const timeout = setTimeout(() => document.addEventListener('click', listener));
      return () => {
        clearTimeout(timeout);
        document.removeEventListener('click', listener);
      };
    }
  }, [searchVisible]);

  const onChangeFacets = (e) => {
    const { name, checked } = e.target;
    const facet = e.target.getAttribute('data-facet');
    const newQuery = updateQuery(name, checked, facet, query);
    setQuery(newQuery);
  };

  const onClearFacets = useCallback(() => setQuery({}), [setQuery]);
  const onChangeText = useCallback((e) => setText(e.target.value), [setText]);

  const [showFilters, setShowfilters] = useState(!!Object.keys(query).length);
  const toggleFilters = useCallback(() => setShowfilters((value) => !value), []);

  return (
    <div className="home">
      <Seo title="Catalogo video - Festival della Scienza online" />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h1>Catalogo video</h1>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-end">
            <div
              ref={searchWrapperRef}
              className={classNames('home__search-wrapper d-flex d-lg-inline-flex', {
                open: searchVisible
              })}
            >
              <button onClick={showSearch} aria-label="Cerca">
                <SearchIcon />
              </button>
              <input
                ref={searchInputRef}
                type="text"
                value={text}
                onChange={onChangeText}
                autoComplete="off"
                placeholder="Cerca..."
                className="home__search-input mx-3 mx-md-4"
              />
              <button onClick={clearSearch} aria-label="Cancella">
                <span className="icon-close"></span>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pb-4"></div>
        </div>
      </div>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12 d-flex">
            <button
              className={classNames('home__filter-toggle', {
                open: showFilters
              })}
              aria-label={showFilters ? 'Chiudi' : 'Apri'}
              aria-expanded={showFilters}
              onClick={toggleFilters}
            >
              <FiltersIcon />
              <span className="ml-3">{showFilters ? 'Nascondi filtri' : 'Mostra filtri'}</span>
            </button>
            {resultEvents ? <span className="ml-auto">{resultEvents.length} Video</span> : null}
          </div>
        </div>
        <hr className="home__hr" />
        <div className="row py-5">
          <div
            className={classNames('col-12 col-sm-5 col-lg-3', {
              'd-none': !showFilters,
              'd-flex': showFilters
            })}
          >
            <Filters data={resultFilters} onChange={onChangeFacets} onClear={onClearFacets} />
          </div>
          <div
            className={classNames('col-12 text-center', {
              'col-sm-7 col-lg-9': showFilters
            })}
          >
            {!resultEvents || !resultFilters || (user && !userData) ? (
              <span
                className="spinner-border spinner-border-sm mr-1"
                style={{ width: '3rem', height: '3rem' }}
                role="status"
                aria-hidden="true"
              ></span>
            ) : !!resultEvents && !!resultEvents.length ? (
              <div className="row text-left">
                {resultEvents.map((event) => (
                  <div
                    className={classNames('col-12 mb-4', {
                      'col-lg-6 col-xl-4': showFilters,
                      'col-md-6 col-lg-4 col-xl-3': !showFilters
                    })}
                    key={event.id}
                  >
                    <Event
                      data={event}
                      key={event.id}
                      hideBadge={true}
                      cardVertical={true}
                      hideKeywords={true}
                    />
                  </div>
                ))}
              </div>
            ) : events ? (
              <h3 className="text-center py-5">Nessun risultato</h3>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TuttiVideo;
