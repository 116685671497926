import React, { useCallback, useState, useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import queryString from 'query-string';
import { useAuth } from '../context/auth';
import { Redirect, Link, navigate } from '@reach/router';
import Breadcrumbs from '../components/breadcrumbs';
import { getErrorMessage } from '../utils/cognito-error-messages';
import Seo from '../components/seo';

const breadcrumbs = [
  { label: 'Abbonati', link: '/abbonati/' },
  { label: 'Registrati', link: '/registrati/' },
  { label: 'Conferma registrazione' }
];

const ConfermaRegistrazione = ({ location }) => {
  const { code, email } = queryString.parse(location.search);
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [error, setError] = useState(null);
  const [invalid, setInvalid] = useState(null);

  const confirmSignUp = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      setLoading(true);
      try {
        await Auth.confirmSignUp(email, code);
        console.log('Confirmed sign up');
        setConfirmed(true);
      } catch (err) {
        console.log('error confirming sign up:', err);
        if (err.code === 'CodeMismatchException' || err.code === 'ExpiredCodeException') {
          setError(getErrorMessage(err));
          setInvalid(true);
        } else if (err.code === 'NotAuthorizedException') {
          console.warn('Utente già confermato');
          navigate('/login/', { state: { email } });
        } else {
          setError(getErrorMessage(err));
        }
      }
      setLoading(false);
    },
    [email, code]
  );

  const resendCode = useCallback(async () => {
    setLoadingCode(true);
    try {
      await Auth.resendSignUp(email);
      console.log('code resent successfully');
      setError('');
      setInvalid(false);
    } catch (err) {
      console.log('error resending link:', err);
      setError(getErrorMessage(err));
    }
    setLoadingCode(false);
  }, [email]);

  useEffect(() => {
    if (code && email) {
      confirmSignUp();
    }
  }, [code, email, confirmSignUp]);

  if (user) {
    return <Redirect to="/accesso" noThrow />;
  }

  if (!code || !email) {
    return <Redirect to="/login" noThrow />;
  }

  return (
    <div className="container">
      <Seo title="Festival della Scienza online" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>Conferma registrazione</h1>
          {error && !loading && !loadingCode ? (
            <div className="form__err-message my-3">
              <p>{error}</p>
            </div>
          ) : null}
          <div className="my-3">
            <label className="form__label">
              Mail
              <input
                type="text"
                name="email"
                value={email}
                readOnly={true}
                className="form__field"
              />
            </label>
          </div>
          {loading ? (
            <div>
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
              Caricamento...
            </div>
          ) : null}
          {invalid ? (
            <div className="d-flex justify-content-end my-3">
              <button className="btn" onClick={resendCode}>
                {loadingCode ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Caricamento...
                  </>
                ) : (
                  'Reinvia'
                )}
              </button>
            </div>
          ) : null}
          {confirmed ? (
            <div>
              <p>
                Registrazione confermata. Ora puoi fare la{' '}
                <Link to="/login/" state={{ email }}>
                  LOGIN
                </Link>{' '}
                al sito.
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ConfermaRegistrazione;
