import React from 'react';
import Breadcrumbs from '../components/breadcrumbs';
import Seo from '../components/seo';
import { CookieDeclaration } from '../components/cookiebot';

const breadcrumbs = [{ label: 'Cookie Policy' }];

const CookiePolicy = () => {
  return (
    <div className="container" style={{ overflowX: 'hidden' }}>
      <Seo title="Festival della Scienza online" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12">
          <h1>Cookie Policy</h1>
          <h3 className="mb-4">Versione del 04 marzo 2021</h3>
          <p>
            <strong>Titolare del trattamento:</strong> L’Associazione Festival della Scienza (in
            seguito Festival), con sede legale in Genova, 16152, Corso Perrone 24, Tel. 010.6598795,
            Fax 010.598777, e-mail:{' '}
            <a href="mailto:info@festivalscienza.it">info@festivalscienza.it</a>.
          </p>
          <p>
            <strong>A chi è rivolta questa informativa:</strong> visitatori del sito
            www.festivalscienza.online.
          </p>
          <p>
            <strong>Dati forniti volontariamente:</strong> La navigazione sul nostro sito è libera e
            non comporta alcun obbligo per voi di comunicarci i vostri dati personali. Se invece
            desiderate ottenere alcuni specifici servizi o accedere ad aree riservate, vi chiediamo
            di inserire, nei rispettivi moduli presenti sul sito, i dati personali strettamente
            necessari per poter dare riscontro alla vostra richiesta (in linea generale, il
            nominativo e i dati di contatto). L’inserimento di tali dati costituisce un obbligo di
            natura contrattuale, la cui inosservanza non consente l’erogazione del servizio
            richiesto. Per sapere di più su come utilizziamo questi dati ti rimandiamo alla nostra
            informativa sulla privacy.
          </p>
          <p>
            <strong>Dati forniti involontariamente: </strong> Durante la navigazione sul sito
            vengono acquisite automaticamente alcune informazioni che, pur non consentendo la vostra
            identificazione diretta, per loro stessa natura potrebbero rendervi identificabili
            attraverso la combinazione di dati reperibili su internet. Le informazioni vengono
            acquisite in modo automatico tramite i cookie e consistono in: l’indirizzo IP o il nome
            a dominio del computer, l’indirizzo in notazione URI, l’orario della visita o di una
            richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del
            file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal
            server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser
            e del sistema operativo, la permanenza e l’itinerario seguito durante la navigazione
            all’interno del sito, preferenze di lingua e altre.
          </p>
          <p>
            <strong>Cosa sono i cookie:</strong> I Cookie sono piccole stringhe di testo senza le
            quali alcune funzionalità del sito non potrebbero esistere o sarebbero più complesse o
            meno sicure. Le pagine web, infatti, sono prive di memoria e non possono passare
            facilmente informazioni tra di loro. I cookie forniscono una sorta di memoria alle
            pagine web, ad esempio, consentono di effettuare il login su una pagina, di spostarsi su
            altre pagine e di rimanere connessi, di impostare le preferenze per la visualizzazione
            di una pagina e di ricordarle la prossima volta che vi ritornate. Ogni pagina del sito
            che state visitando inserisce i cookie sul vostro computer e li riceve indietro.
          </p>
          <p>
            <strong>Quali cookie sono installati sul nostro sito e per quale motivo:</strong> Sul
            nostro sito sono presenti i cookie cosiddetti di prima parte, vale a dire quelli
            installati e gestiti esclusivamente da noi:
            <br />
            - cookie tecnici, di sessione e persistenti, necessari per la funzionalità del sito e
            per rendere migliore la vostra esperienza di navigazione, che abbiamo provveduto ad
            impostare tenendo conto delle funzioni quali, ad esempio, la scelta di una pagina, le
            preferenze di lingua, l’accesso ad un servizio o ad aree riservate etc.;
            <br />
            i cookie di sessione vengono memorizzati solo temporaneamente sul vostro browser e
            distrutti quando questo viene chiuso, mentre i cookie persistenti vengono salvati sul
            vostro computer per un determinato periodo di tempo; <br />
            - utilizziamo altresì i cookie analitici per effettuare analisi di statistica aggregata
            sui modi con cui i visitatori navigano o utilizzano il nostro sito, con l’obiettivo di
            migliorarne le prestazioni e i contenuti. I cookie analitici sono cookie persistenti che
            possono essere assimilabili a cookie tecnici quando sono utilizzati direttamente da un
            titolare per finalità di miglioramento del sito.
            <br />
            Alcuni cookie vengono trasmessi solo tramite il protocollo HTTPS. In questo modo si
            garantisce che le informazioni vengano criptate durante il passaggio tra il sito e il
            browser.
            <br />
            Senza queste tipologie di cookie alcune funzionalità del sito potrebbero non essere
            efficienti o sarebbero più complesse o meno sicure.
          </p>
          <p>
            <u>Cookie di terze parti:</u> Sul sito sono inoltre presenti elementi, quali, immagini,
            suoni o specifici link a pagine web di altri domini, che risiedono su server diversi da
            quello che ospita il nostro sito. Vengono aggiunti per ottenere le funzionalità
            ulteriori, come ad esempio la possibilità di condividere contenuti tramite i social
            network.
            <br />
            L’inserimento dei plug-in social nelle pagine del sito comporta che, quando un
            visitatore entra nella pagina di un sito dove è collocato un plug-in, il suo browser
            invia automaticamente informazioni relative all’indirizzo IP e alla stringa del browser
            alla piattaforma social fornitore del plug-in. Ad esempio, se si visita un sito che ha
            un video di YouTube in una delle sue pagine, questo è stato incluso dal proprietario del
            sito web, utilizzando un pezzo di codice fornito da YouTube. YouTube sarà quindi in
            grado di impostare i cookie attraverso questo codice, e saprà che avete guardato quel
            video, o anche solo visitato la pagina in cui si trova il video.
            <br />
            Se cliccate su una delle icone presenti sulle pagine del nostro sito, sarete
            automaticamente rinviati alla pagina del sito esterno, ove i vostri dati saranno
            trattati secondo la privacy policy del titolare del trattamento associato all’icona
            scelta.
            <br />
            Cliccate <a href="http://www.google.com/intl/en/policies/privacy/">qui</a> per
            visualizzare privacy policy di Google.
            <br />
            Cliccate <a href="http://www.google.com/intl/en/policies/privacy/">qui</a> per
            visualizzare privacy policy di Instagram;
            <br />
            Cliccate <a href="http://www.google.com/intl/en/policies/privacy/">qui</a> per
            visualizzare privacy policy di Youtube;
            <br />
            Cliccate <a href="http://www.google.com/intl/en/policies/privacy/">qui</a> per
            visualizzare privacy policy di twitter;
            <br />
            Cliccate <a href="http://www.google.com/intl/en/policies/privacy/">qui</a> per
            visualizzare privacy policy di facebook;
            <br />
            Cliccate <a href="http://www.google.com/intl/en/policies/privacy/">qui</a> per
            visualizzare privacy policy di flikr;
          </p>
          <p>
            <u>Tracciamento online:</u> Noi non vi tracciamo. Tuttavia vi informiamo che i cookie
            sono il mezzo più comune, ma non l’unico, per il tracciamento online. Molti siti web
            utilizzano Web Beacon, che viene collocato su un sito web o in una e-mail per monitorare
            il comportamento dell'utente che visita il sito o che riceve l'e-mail. Ad esempio,
            quando il browser si connette a un sito con il web beacon, e il browser richiede al
            server web di scaricare un’immagine, insieme a questa richiesta possono essere inviati
            dettagli come l'indirizzo IP, il tipo di browser, l'ora di accesso o i cookie
            precedentemente impostati. Di regola, se i cookie sono disattivati, i web beacon non
            saranno in grado di tracciare un utente, ma possono comunque essere utilizzati per
            effettuare visite anonime.
          </p>
          <p>
            <strong>Come disabilitare i cookie:</strong> I browser web generalmente accettano
            automaticamente i cookie ma per ognuno di essi è possibile modificare le impostazioni al
            fine di limitare o impedire l’uso dei cookie. Ricordiamo, tuttavia, che la
            disabilitazione o la cancellazione dei cookie potrebbe impedire o compromettere la
            fruizione di alcune aree o servizi del nostro sito e che le impostazioni devono essere
            modificate separatamente in ogni browser e computer utilizzato.
            <br />
            Potete impostare il browser utilizzato per la navigazione in modo da impedire il
            tracciamento o installazione di alcuni o di tutti i cookie.
          </p>
          <p>
            <strong>
              <u>Come fare:</u>
            </strong>
            <br />
            <strong>Crome: </strong>
            <em>
              cliccare in alto a destra della finestra- quindi, “impostazioni” – “privacy e
              sicurezza” – “cookie e altri dati dei siti” – “blocca i cookie”;
            </em>
            <br />
            <strong>Edge: </strong>
            <em>
              cliccare in alto a destra della finestra – quindi, “impostazioni” - “privacy, ricerca
              e servizi” - “prevenzione dal rilevamento”;
            </em>
            <br />
            <strong>Opera: </strong>
            <em>
              cliccare su icona “impostazioni” – poi “impostazioni di base” – “blocca tracker e
              blocca gli annunci pubblicitari”;
            </em>
            <br />
            <strong>Firefox: </strong>
            <em>
              cliccare in alto a destra della finestra su “apri menù” – quindi “opzioni” – “privacy
              e sicurezza” – “protezione anti tracciamento”;
            </em>
            <br />
            <strong>Safari: </strong>
            <em>clicca sull’app “safari” - quindi “preferenze” – “privacy”</em>
          </p>
          <p>
            Per maggiori informazioni sulla gestione dei cookie, riportiamo anche i link relativi ai
            browser più utilizzati:
            <br />
            <strong>Internet Explorer™: </strong>
            <a href="http://windows.microsoft.com/it-IT/windows-vista/Block-or-allow-cookies">
              http://windows.microsoft.com/it-IT/windows-vista/Block-or-allow-cookies
            </a>
            <br />
            <strong>Safari™: </strong>
            <a href="http://www.apple.com/it/support/">http://www.apple.com/it/support/</a>
            <br />
            <strong>Chrome™: </strong>
            <a href=" https://support.google.com/chrome/answer/95647?hl=it&hlrm=en">
              {' '}
              https://support.google.com/chrome/answer/95647?hl=it&hlrm=en
            </a>
            <br />
            <strong>Firefox™: </strong>
            <a href="http://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">
              http://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie
            </a>
            <br />
            <strong>Opera™: </strong>
            <a href=" http://help.opera.com/Windows/10.20/it/cookies.html">
              {' '}
              http://help.opera.com/Windows/10.20/it/cookies.html
            </a>
          </p>
          <p>
            Per ulteriori informazioni su come visualizzare i cookie che sono stati impostati sul
            Suo dispositivo, su come gestirli ed eliminarli, visitate i seguenti siti:
            <a href="www.youronlinechoices.com">www.youronlinechoices.com</a>;{' '}
            <a href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a>.
          </p>
          <CookieDeclaration />
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
