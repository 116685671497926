import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-overlays';

const renderBackdrop = () => <div className="modal-backdrop"></div>;

const ForgotPasswordModal = ({ show, onHide, email: formEmail, submit, loading }) => {
  const [email, setEmail] = useState('');
  const onChange = useCallback((e) => setEmail(e.target.value), []);
  const onSubmit = useCallback(() => submit(email), [submit, email]);
  useEffect(() => {
    setEmail(formEmail);
  }, [formEmail]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <div className="modal">
        <button aria-label="close" className="modal__close" onClick={onHide} />
        <h2 id="modal-label">Password dimenticata</h2>
        <p>
          Inserisci l'indirizzo email di registrazione. Se risulterà registrato, ti invieremo un
          link per poter cambiare la password.
        </p>
        <input type="email" value={email} onChange={onChange} className="form__field" />
        <div className="mt-4 d-flex justify-content-end">
          <button className="btn" disabled={loading} onClick={onSubmit}>
            {loading ? (
              <div className="d-flex align-items-center">
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Caricamento...
              </div>
            ) : (
              'Invia'
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
