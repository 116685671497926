import { useCallback, useMemo } from 'react';
import { useAuth } from './auth';
import {
  getUserData as apiGetUserData,
  validateTicket as apiValidateTicket,
  prepareForm as apiprepareForm,
  requestStreaming as apiRequestStreaming,
  sendQuestion as apiSendQuestion,
  subscribeStream as apiSubscribeStream,
  checkQueryString as apiCheckQueryString
} from './api';

const useApi = () => {
  const { signOut } = useAuth();
  const getUserData = useCallback(() => apiGetUserData(signOut), [signOut]);
  const validateTicket = useCallback(
    (ticketCode) => apiValidateTicket(ticketCode, signOut),
    [signOut]
  );
  const prepareForm = useCallback(() => apiprepareForm(signOut), [signOut]);
  const requestStreaming = useCallback(
    (eventId) => apiRequestStreaming(eventId, signOut),
    [signOut]
  );
  const sendQuestion = useCallback(
    (message, title, set) => apiSendQuestion(message, title, set, signOut),
    [signOut]
  );
  const subscribeStream = useCallback(() => apiSubscribeStream(signOut), [signOut]);
  const checkQueryString = useCallback((location) => apiCheckQueryString(location), []);

  return useMemo(
    () => ({
      getUserData,
      validateTicket,
      prepareForm,
      requestStreaming,
      sendQuestion,
      subscribeStream,
      checkQueryString
    }),
    [
      getUserData,
      validateTicket,
      prepareForm,
      requestStreaming,
      sendQuestion,
      subscribeStream,
      checkQueryString
    ]
  );
};

export default useApi;
