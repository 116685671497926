import React from 'react';
import Breadcrumbs from '../components/breadcrumbs';
import Seo from '../components/seo';
import Link from '../components/link';
import { Redirect } from '@reach/router';
import { useAuth } from '../context/auth';

const breadcrumbs = [{ label: 'Acquisto fallito' }];

const AcquistoFallito = () => {
  const { user } = useAuth();

  if (!user) {
    return <Redirect to="/login" noThrow />;
  }
  return (
    <div className="container">
      <Seo title="Acquisto completato" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>Acquisto fallito</h1>
          <Link to="/accesso/">Riprova</Link>
          {/* <Link to="/codice-biglietto/">Riprova</Link>  */}
        </div>
      </div>
    </div>
  );
};

export default AcquistoFallito;
