import React from 'react';
import Seo from '../components/seo';
import Breadcrumbs from '../components/breadcrumbs';

const breadcrumbs = [{ label: 'Stream Creator', link: '/stream-creator/' }, { label: 'Fatto!' }];
const AvvenutaIscrizioneStream = () => {
  return (
    <div className="section">
      <div className="container">
        <Seo title="Festival della Scienza online" />
        <div className="row">
          <div className="col-12">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1>Fatto!</h1>
            <p>Grazie per l'iscrizione! Riceverai a breve le nostre comunicazioni.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvvenutaIscrizioneStream;
