const conferenze = {
  1: 'Lectio magistralis',
  2: 'Dialogo internazionale',
  3: 'Caffè scientifico',
  4: 'Conversazione',
  5: 'Dialogo',
  6: 'Conferenza/Spettacolo',
  7: 'Evento Speciale',
  8: 'Incontro',
  9: 'Convegno',
  10: 'Seminario',
  11: 'Tavola rotonda',
  13: 'Documentario/Film',
  14: 'Webconference',
  15: 'Webinar',
  16: 'Webconference',
  17: 'Space walk: in orbita',
  18: '#telospiega',
  19: 'Sound experience',
  20: 'Performance',
  21: 'Keynote',
  22: 'One2many',
  23: 'One2one',
  24: 'Live room',
  25: 'Big bang',
  26: 'Space gig'
};

export default conferenze;
