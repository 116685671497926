const discipline = [
  {
    value: '0',
    label: 'Le discipline'
  },
  {
    value: '1',
    label: "Scienze della Terra, del Mare e dell'Ambiente"
  },
  {
    value: '2',
    label: 'Scienze della Vita'
  },
  {
    value: '3',
    label: 'Chimica e materiali'
  },
  {
    value: '4',
    label: 'Fisica e materia'
  },
  {
    value: '5',
    label: 'Energia, tecnologia, ICT e industria'
  },
  {
    value: '6',
    label: 'Astrofisica, cosmologia e spazio'
  },
  {
    value: '7',
    label: 'Medicina e Scienze Biomediche'
  },
  {
    value: '8',
    label: 'Matematica, Logica e Informatica'
  },
  {
    value: '9',
    label: 'Scienze umane, arte e filosofia'
  },
  {
    value: '10',
    label: 'Scienza e società'
  }
];

export default discipline;
