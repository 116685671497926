import { sortAlphabetical } from './sort';

export const relatoriFromEvents = (events) => {
  if (!events) return [];
  const relatoriMap = new Map();
  const uniqueRelatori = [];
  for (let event of events) {
    for (let relatore of event.relatori) {
      if (!relatoriMap.has(relatore.idrelatore)) {
        relatoriMap.set(relatore.idrelatore, true);
        uniqueRelatori.push(relatore);
      }
    }
  }
  return sortAlphabetical(uniqueRelatori, 'cognome');
};
