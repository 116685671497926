import React, { memo, useEffect, useRef } from 'react';
import CookieBot from 'react-cookiebot';

export const domainGroupId = 'cd6c178e-fd97-4d8a-a7d6-4d18fc391b50';

const CookieBotComponent = memo(() => <CookieBot domainGroupId={domainGroupId} />);

export const CookieDeclaration = memo(() => {
  const ref = useRef();

  useEffect(() => {
    const existingScript = document.getElementById('CookieDeclaration');
    if (existingScript) {
      existingScript.remove();
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'CookieDeclaration';
    script.src = `https://consent.cookiebot.com/${domainGroupId}/cd.js`;
    script.async = true;
    ref.current.appendChild(script);
  }, []);

  return <div ref={ref}></div>;
});

export default CookieBotComponent;
