import React from 'react';
import Seo from '../components/seo';

const Page404 = () => (
  <div className="home">
    <Seo title="404: Pagina non trovata" robots="noindex,nofollow" />
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Pagina non trovata</h1>
        </div>
      </div>
    </div>
  </div>
);

export default Page404;
