// import festivalBg from '../images/bg_2024.jpg';

const launchBoxes = [
  // {
  //   title: 'Festival della Scienza 2024',
  //   tag: '',
  //   logo: '',
  //   text: 'Le conferenze della ventiduesima edizione disponibili on demand',
  //   date: '',
  //   btn: {
  //     label: 'registrati',
  //     link: '/registrati/'
  //   },
  //   btnLogged: {
  //     label: 'Programma degli eventi',
  //     link: '/festival-2024/'
  //   },
  //   background: festivalBg
  // },
  {
    title: 'La divulgazione scientifica a portata di click',
    tag: '',
    logo: '',
    text: 'festivalscienza.online è il nuovo portale dedicato alla divulgazione scientifica curato dall’Associazione Festival della Scienza',
    date: '',
    btn: {
      label: 'registrati',
      link: '/registrati/'
    },
    btnLogged: {
      label: 'Scopri',
      link: '/tutti-video'
    },
    background: ''
  }
];

export default launchBoxes;
