import Auth from '@aws-amplify/auth';
import axios from 'axios';

const API = {
  get: (path, request) => {
    return axios.request({
      ...request,
      method: 'get',
      url: path
    });
  },
  post: (path, request) => {
    return axios.request({
      ...request,
      method: 'post',
      url: path
    });
  }
};

const getRequest = async (queryStringParameters, body, signOut, config = {}) => {
  const request = {
    response: true,
    headers: { 'content-type': 'application/json' }
  };
  if (signOut) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      request.headers.Authorization = token;
    } catch (err) {
      console.error('Auth error: ', err);
      if (
        err === 'not authenticated' ||
        (err.response && err.response.data.__type === 'NotAuthorizedException')
      ) {
        await signOut();
      }
      throw err;
    }
  }
  if (queryStringParameters) {
    request.params = queryStringParameters;
  }
  if (body) {
    request.data = body;
  }
  return { ...request, ...config };
};

const getUserData = async (signOut) => {
  console.log('retrieve user data');
  const path = '/users/user-data';
  const request = await getRequest(null, null, signOut);
  return await API.get(path, request);
};

const prepareForm = async (signOut) => {
  console.log('prepare form');
  const path = '/users/prepare-form';
  const request = await getRequest(null, null, signOut);
  return await API.get(path, request);
};

const validateTicket = async (ticketCode, signOut) => {
  const path = '/users/validate-ticket';
  const request = await getRequest(null, { ticketCode }, signOut);
  return await API.post(path, request);
};

const requestStreaming = async (eventId, signOut) => {
  const path = '/users/request-streaming';
  const request = await getRequest(null, { eventId }, signOut);
  return await API.post(path, request);
};

const sendQuestion = async (message, title, set, signOut) => {
  const path = '/users/send-question';
  const request = await getRequest(null, { message, title, set }, signOut);
  return await API.post(path, request);
};

const subscribeStream = async (signOut) => {
  const path = '/users/mailup-subscribe';
  const request = await getRequest(null, { listId: 8 }, signOut);
  return await API.post(path, request);
};

const checkQueryString = async (location) => {
  const path = '/users/check-query-string';
  const request = await getRequest(null, { location });
  return await API.post(path, request);
};

export {
  getUserData,
  validateTicket,
  prepareForm,
  requestStreaming,
  sendQuestion,
  subscribeStream,
  checkQueryString
};
