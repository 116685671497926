const dates = [
  {
    value: '0',
    label: 'Tutte le date del festival'
  },
  {
    value: '2410',
    label: 'Giovedì 24 ottobre'
  },
  {
    value: '2510',
    label: 'Venerdì 25 ottobre'
  },
  {
    value: '2610',
    label: 'Sabato 26 ottobre'
  },
  {
    value: '2710',
    label: 'Domenica 27 ottobre'
  },
  {
    value: '2810',
    label: 'Lunedì 28 ottobre'
  },
  {
    value: '2910',
    label: 'Martedì 29 ottobre'
  },
  {
    value: '3010',
    label: 'Mercoledì 30 ottobre'
  },
  {
    value: '3110',
    label: 'Giovedì 31 ottobre'
  },
  {
    value: '0111',
    label: 'Venerdì 1 novembre'
  },
  {
    value: '0211',
    label: 'Sabato 2 novembre'
  },
  {
    value: '0311',
    label: 'Domenica 3 novembre'
  }
];

export default dates;
