import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { navigate } from '@reach/router';
import qs from 'qs';
import classNames from 'classnames';
import Event from '../components/event';
import Seo from '../components/seo';
import dates from '../utils/dates';
import discipline from '../utils/discipline';
import tipologie from '../utils/tipologie';
import { useAuth } from '../context/auth';
import { sortByOrarioCode } from '../utils/sort';
import useAllEvents from '../hooks/use-all-event';
import { relatoriFromEvents } from '../utils/relatori';
// import imgERG from '../images/sponsor/erg_logo_1x.png';
// import imgERG2 from '../images/sponsor/erg_logo_2x.png';

const disciplineFromEvent = (events, discipline) => {
  if (!events) return [];
  const eventsDiscipline = events
    .reduce((res, event) => {
      const eventDisciplina = discipline.find((item) => item.value === event.disciplina);
      if (eventDisciplina && !res.some((item) => item.value === eventDisciplina.value)) {
        res.push(eventDisciplina);
      }
      return res;
    }, [])
    .sort((a, b) => (a.label > b.label ? 1 : -1));
  return [discipline.find((item) => item.value === '0')].concat(eventsDiscipline);
};

const filterAndSort = (events) =>
  events.filter((event) => event.origine === '2024').sort(sortByOrarioCode);

const Festival = ({ location }) => {
  const { user, userData } = useAuth();

  const query = qs.parse(location.search ? location.search.slice(1) : '');
  const { date = '0', relatore = '0', disciplina = '0', tipologia = '0' } = query;

  const { events } = useAllEvents(filterAndSort);
  const relatori = useMemo(() => relatoriFromEvents(events), [events]);
  const listaDiscipline = useMemo(() => disciplineFromEvent(events, discipline), [events]);

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      const {
        date = '0',
        relatore = '0',
        disciplina = '0',
        tipologia = '0'
      } = Object.assign({}, query, { [name]: value });
      // console.log('onchange: ', date, relatore, disciplina);
      const resultQuery = qs.stringify({
        ...(relatore !== '0' && { relatore }),
        ...(disciplina !== '0' && { disciplina }),
        ...(date !== '0' && { date }),
        ...(tipologia !== '0' && { tipologia })
      });
      const path = [location.pathname, resultQuery].filter((item) => !!item).join('?');
      navigate(path);
    },
    [location.pathname, query]
  );

  const [resultEvents, setResultEvents] = useState(null);
  useEffect(() => {
    if (events) {
      const matchData = (event) =>
        date === '0' || (event.orario_code && event.orario_code.includes(date));
      const matchRelatore = (event) =>
        relatore === '0' ||
        (event.relatori &&
          event.relatori.find &&
          event.relatori.find((item) => item.idrelatore === relatore));
      const matchDisciplina = (event) => disciplina === '0' || event.disciplina === disciplina;
      const matchGenere = (event) =>
        tipologia === '0' ||
        (tipologia === 'conf' && event.t_c) ||
        (tipologia === 'spet' && event.t_s);
      if (relatore === '0' && disciplina === '0' && date === '0' && tipologia === '0') {
        setResultEvents(events);
      } else {
        setResultEvents(
          events.filter(
            (event) =>
              matchRelatore(event) &&
              matchDisciplina(event) &&
              matchData(event) &&
              matchGenere(event)
          )
        );
      }
    } else {
      setResultEvents(null);
    }
  }, [events, relatore, disciplina, date, tipologia]);

  const [loadedHash, setLoadedHash] = useState(false);
  useEffect(() => {
    if (resultEvents?.length && !loadedHash) {
      setLoadedHash(true);
      const matches = location.hash ? /#event-(\d+)/.exec(location.hash) : null;
      if (matches && matches.length > 1) {
        const id = matches[1];
        if (resultEvents.some((event) => event.id === id)) {
          const element = document.getElementById(`event-${id}`);
          if (element) {
            element.scrollIntoView();
          }
        }
      }
    }
  }, [resultEvents, location.hash, loadedHash]);

  const [showFilters, setShowfilters] = useState(false);
  const toggleFilters = useCallback(() => setShowfilters((value) => !value), []);

  return (
    <div className="home">
      <Seo title="Festival della Scienza online" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Conferenze on-demand</h1>
          </div>
        </div>
        {/* <div className="row mb-4">
          <div className="col-12 text-right">
            <a
              href="https://www.erg.eu/it/home"
              target="_blank"
              rel="noopener noreferrer"
              className="home__sponsor"
            >
              <img src={imgERG} alt="Powered by ERG" srcSet={`${imgERG2} 2x`} />
            </a>
          </div>
        </div> */}
      </div>
      <div className="container">
        {events && (
          <>
            <div className="row d-md-none mb-3">
              <div className="col-12 text-right">
                Apri filtri
                <button
                  className={classNames(`ml-3 toggle`, {
                    'toggle--open': showFilters
                  })}
                  aria-label={showFilters ? 'Chiudi' : 'Apri'}
                  aria-expanded={showFilters}
                  onClick={toggleFilters}
                ></button>
              </div>
            </div>
            <div
              className={classNames(`row d-md-flex mb-4`, {
                'd-flex': showFilters,
                'd-none': !showFilters
              })}
            >
              <div className="mt-3 mt-lg-0 col-md-6 col-lg-3">
                <div className="select">
                  <select value={date} name="date" onChange={onChange}>
                    {dates.map((date) => (
                      <option key={date.value} value={date.value}>
                        {date.label}
                      </option>
                    ))}
                  </select>
                  <span className="select__arrow"></span>
                </div>
              </div>
              <div className="mt-3 mt-lg-0 col-md-6 col-lg-3">
                <div className="select">
                  <select value={disciplina} name="disciplina" onChange={onChange}>
                    {listaDiscipline.map((disciplina) => (
                      <option key={disciplina.value} value={disciplina.value}>
                        {disciplina.label}
                      </option>
                    ))}
                  </select>
                  <span className="select__arrow"></span>
                </div>
              </div>
              <div className="mt-3 mt-lg-0 col-md-6 col-lg-3">
                <div className="select">
                  <select value={relatore} name="relatore" onChange={onChange}>
                    <option key="0" value="0">
                      I protagonisti
                    </option>
                    {relatori &&
                      relatori.map((relatore) => (
                        <option key={relatore.idrelatore} value={relatore.idrelatore}>
                          {relatore.cognome} {relatore.nome}
                        </option>
                      ))}
                  </select>
                  <span className="select__arrow"></span>
                </div>
              </div>
              <div className="mt-3 mt-lg-0 col-md-6 col-lg-3">
                <div className="select">
                  <select value={tipologia} name="tipologia" onChange={onChange}>
                    {tipologie.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <span className="select__arrow"></span>
                </div>
              </div>
            </div>
          </>
        )}
        {!events || !relatori || (user && !userData) ? (
          <div className="row">
            <div className="col-12 text-center py-5">
              <span
                className="spinner-border spinner-border-sm mr-1"
                style={{ width: '3rem', height: '3rem' }}
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </div>
        ) : !!resultEvents && !!resultEvents.length ? (
          resultEvents.map((event) => (
            <Event data={event} hideBadge={true} showOrario={true} key={event.id} />
          ))
        ) : events ? (
          <h3 className="text-center py-5">Nessun risultato</h3>
        ) : null}
      </div>
    </div>
  );
};

export default Festival;
