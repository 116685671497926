import React, { useCallback, useState } from 'react';
import Auth from '@aws-amplify/auth';
import queryString from 'query-string';
import { navigate, Redirect } from '@reach/router';
import { useAuth } from '../context/auth';
import Breadcrumbs from '../components/breadcrumbs';
import { getErrorMessage } from '../utils/cognito-error-messages';
import Seo from '../components/seo';

const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/;
const validateParameters = (password, password2) => {
  const errors = {};
  if (password && !PASSWORD_REGEX.test(password)) {
    errors.password = 'invalidPassword';
  }
  if (password && password2 && password !== password2) {
    errors.password2 = 'passwordMismatch';
  }
  return errors;
};
const errorMessages = {
  invalidPassword: 'Formato password non valido',
  passwordMismatch: 'La password non è uguale'
};
const isFilled = (obj) => Object.values(obj).filter((val) => !!val).length;
const breadcrumbs = [{ label: 'Login', link: '/login/' }, { label: 'Password dimenticata' }];

const PasswordDimenticata = ({ location }) => {
  const { code, email } = queryString.parse(location.search);
  const { user, saveUser } = useAuth();

  const [password, setPassword] = useState('');
  const onChangePassword = useCallback((e) => setPassword(e.target.value), []);
  const [password2, setPassword2] = useState('');
  const onChangePassword2 = useCallback((e) => setPassword2(e.target.value), []);

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const filledParams = password && password2;

  const submit = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      if (!filledParams) return;
      const errs = validateParameters(password, password2);
      if (isFilled(errs)) {
        setErrors(errs);
        return;
      }
      setLoading(true);
      try {
        await Auth.forgotPasswordSubmit(email, code, password);
      } catch (err) {
        console.error('error forgotPasswordSubmit: ', err);
        setLoading(false);
        setError(getErrorMessage(err));
        return;
      }
      try {
        await Auth.signIn(email, password);
      } catch (err) {
        console.error('Error in login: ', err);
        setError(getErrorMessage(err));
        return;
      }
      const loggedUser = await Auth.currentAuthenticatedUser();
      saveUser(loggedUser);
      setLoading(false);
      setError('');
      navigate('/accesso/');
    },
    [saveUser, email, password, password2, code, filledParams]
  );

  if (user) {
    return <Redirect to="/accesso" noThrow />;
  }

  if (!code || !email) {
    return <Redirect to="/login" noThrow />;
  }

  return (
    <div className="container">
      <Seo title="Festival della Scienza online" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>Password dimenticata</h1>
          <form className="form" onSubmit={submit}>
            <div className="my-3">
              <label className="form__label">
                Mail
                <input
                  type="text"
                  name="email"
                  value={email}
                  readOnly={true}
                  className="form__field"
                />
              </label>
            </div>
            <div className="my-3">
              <label className="form__label">
                Password
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  placeholder=""
                  disabled={loading}
                  maxLength={320}
                  className="form__field"
                />
              </label>
              {errors['password'] ? (
                <div className="form__error">{errorMessages[errors['password']]}</div>
              ) : null}
              <div className="text-small">
                <p>
                  La password deve essere di almeno 8 caratteri, deve avere almeno una maiuscola,
                  una minuscola, un numero e un simbolo
                </p>
              </div>
            </div>
            <div className="my-3">
              <label className="form__label">
                Conferma Password
                <input
                  type="password"
                  name="password2"
                  value={password2}
                  onChange={onChangePassword2}
                  placeholder=""
                  disabled={loading}
                  maxLength={320}
                  className="form__field"
                />
              </label>
              {errors['password2'] ? (
                <div className="form__error">{errorMessages[errors['password2']]}</div>
              ) : null}
            </div>
            {error && !loading ? (
              <div className="my-3 form__err-message">
                <p>{error}</p>
              </div>
            ) : null}
            <div className="d-flex flex-column align-items-end">
              <button type="submit" className="btn" disabled={loading || !filledParams}>
                {loading ? (
                  <div className="d-flex align-items-center">
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Caricamento...
                  </div>
                ) : (
                  'Cambia password'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordDimenticata;
