import { createContext, useContext, useState, useCallback, useLayoutEffect, useMemo } from 'react';
import Auth from '@aws-amplify/auth';
import { getUserData } from './api';

const LOGGED_KEY = 'logged-user';

export const AuthContext = createContext();

const loadUser = () => {
  const storageUser = localStorage.getItem(LOGGED_KEY);
  return storageUser ? JSON.parse(storageUser) : null;
};

export const useAuthData = () => {
  const [user, setUser] = useState(loadUser);
  const [userData, setUserData] = useState(null);

  const saveUser = useCallback((user) => {
    if (user) {
      localStorage.setItem(LOGGED_KEY, JSON.stringify(user));
    } else {
      localStorage.removeItem(LOGGED_KEY);
      setUserData(null);
    }
    setUser(user);
  }, []);

  const signOut = useCallback(async () => {
    try {
      await Auth.signOut();
      saveUser();
    } catch (err) {
      console.error('Error logging out: ', err);
    }
  }, [saveUser]);

  const updateUserData = useCallback(
    async (data) => {
      if (data) {
        setUserData(data);
      } else {
        const {
          data: { userData }
        } = await getUserData(signOut);
        setUserData(userData);
      }
    },
    [setUserData, signOut]
  );

  useLayoutEffect(() => {
    if (user) {
      Auth.currentAuthenticatedUser()
        .then(() => {
          updateUserData();
        })
        .catch(() => saveUser());
    }
  }, [user, saveUser, updateUserData]);

  return useMemo(
    () => ({ user, userData, updateUserData, saveUser, signOut }),
    [user, userData, updateUserData, saveUser, signOut]
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
