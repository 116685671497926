import React from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';
import Event from '../components/event';

const settings = {
  dots: false,
  infinite: false,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 998,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 576,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const SliderEvents = ({ events }) => {
  return (
    <div>
      <Slider
        {...settings}
        className={classnames('slick-slider slider-events', {
          'slider-events--mb-large': events.length > 11
        })}
      >
        {events && events.map((event) => <Event data={event} key={event.id} cardVertical={true} />)}
      </Slider>
    </div>
  );
};

export default SliderEvents;
