import React from 'react';
import { useAuth } from '../context/auth';
import { Link } from '@reach/router';
import dayjs from 'dayjs';
import Breadcrumbs from '../components/breadcrumbs';
import Seo from '../components/seo';

const breadcrumbs = [{ label: 'I miei dati' }];

const MieiDati = () => {
  const { userData } = useAuth();
  return (
    <div className="container">
      <Seo title="I miei dati della scienza online" />
      <div className="row">
        <div className="col-12">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 offset-lg-2 mb-5">
          <h1>I miei dati</h1>
          {userData ? (
            <>
              <div>Nome:</div>
              <hr />
              <div className="mb-4">
                <strong>{userData.name}</strong>
              </div>
              <div>Cognome:</div>
              <hr />
              <div className="mb-4">
                <strong>{userData.surname}</strong>
              </div>
              <div>Email:</div>
              <hr />
              <div className="mb-4">
                <strong>{userData.email}</strong>
              </div>
              {/* <div className="mb-3">Nome: <strong>{userData.name}</strong></div>
              <div className="mb-3">Cognome: <strong>{userData.surname}</strong></div>
              <div className="mb-3">Email: <strong>{userData.email}</strong></div> */}
            </>
          ) : (
            <span className="spinner-border mr-1" role="status" aria-hidden="true"></span>
          )}
        </div>
      </div>
      {userData && userData.abbonamento && (
        <div className="row">
          <div className="col-lg-8 offset-lg-2 mb-5">
            <h1>Il mio abbonamento{userData.expireDate ? ' (scaduto)' : ''}</h1>
            <div className="option-box mt-3">
              <div className="row">
                <div className="col-md-7">
                  <h2>Abbonamento festival</h2>
                  {userData.paymentData ? <p>(Acquisto online)</p> : <p>(Gratis)</p>}
                </div>
                <div className="col-md-5 text-right">
                  <Link to="/" className="btn">
                    Vai alla home
                  </Link>
                </div>
              </div>
              <hr className="my-3"></hr>
              <div className="d-flex justify-content-end text-small">
                {userData.paymentData ? (
                  <>
                    {userData.paymentData.orderId ? (
                      <span className="mr-3">Ordine #{userData.paymentData.orderId}</span>
                    ) : null}
                    {userData.paymentData.date ? (
                      <span className="mr-4">
                        Effettuato il {dayjs(userData.paymentData.date).format('YYYY-MM-DD')}
                      </span>
                    ) : null}
                    {userData.expireDate ? (
                      <span className="mr-4">
                        Scaduto il {dayjs(userData.expireDate, 'DD-MM-YYYY').format('YYYY-MM-DD')}
                      </span>
                    ) : null}
                    <span className="mr-3">Totale: 10,00 €</span>
                  </>
                ) : (
                  <>
                    <span className="mr-4">Codice biglietto {userData.ticketCode}</span>
                    {userData.expireDate ? (
                      <span className="mr-3">
                        Scaduto il {dayjs(userData.expireDate, 'DD-MM-YYYY').format('YYYY-MM-DD')}
                      </span>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MieiDati;
